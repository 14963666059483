import { useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { setCurrentStep, setCompletedSteps } from '@store/slices/stepsSlice';
export const useResetToStep = () => {
    const dispatch = useDispatch();
    const { currentStep, completedSteps } = useSelector((state) => ({
        currentStep: state.steps.currentStep,
        completedSteps: state.steps.completedSteps
    }), shallowEqual);
    const handler = useCallback((stepName) => {
        if (currentStep === stepName) {
            return;
        }
        let newCompletedSteps = [...completedSteps];
        newCompletedSteps = newCompletedSteps.slice(0, newCompletedSteps.indexOf(stepName));
        dispatch(setCompletedSteps(newCompletedSteps));
        dispatch(setCurrentStep(stepName));
    }, [currentStep, completedSteps]);
    return [handler];
};
