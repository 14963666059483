import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import { useWindowSize } from '@frontend-components/hooks/useWindowSize';
import { dropdownSettings } from '../../AddressAutoComplete.constants';
import styles from './Dropdown.module.scss';
const Dropdown = ({ inputRef, autoCompleteItems, onItemSelect, highlightedItemIndex }) => {
    const { height: windowHeight } = useWindowSize();
    const dropdownRef = useRef(null);
    const [dropdownStyling, setDropdownStyling] = useState({
        opacity: 0,
        width: 0,
        maxHeight: dropdownSettings.maxHeight,
        top: 0,
        left: 0
    });
    useLayoutEffect(() => {
        if (windowHeight === 0)
            return;
        const dropdownStyles = calculateDropdownPosition();
        if (dropdownStyles != null) {
            setDropdownStyling(dropdownStyles);
        }
    }, [windowHeight]);
    useEffect(() => {
        scrollToHighlightedItem();
    }, [highlightedItemIndex]);
    return createPortal(_jsx("div", { ref: dropdownRef, className: styles.wrapper, style: dropdownStyling, role: "presentation", children: autoCompleteItems.map((item, key) => {
            var _a;
            return (_jsx("div", { onMouseDown: () => onItemSelect(item), className: classnames(styles.item, { [styles.highlighted]: key === highlightedItemIndex }), role: "presentation", children: (_a = item === null || item === void 0 ? void 0 : item.full_address) !== null && _a !== void 0 ? _a : '' }, item === null || item === void 0 ? void 0 : item.full_address));
        }) }), document.body);
    function calculateDropdownPosition() {
        if (inputRef.current == null || dropdownRef.current == null)
            return null;
        const inputWrapperRect = inputRef.current.getBoundingClientRect();
        const windowScrollTop = window.scrollY;
        const dropdownOffsetTop = inputWrapperRect.top + inputWrapperRect.height + dropdownSettings.gap + windowScrollTop;
        let height = dropdownSettings.maxHeight;
        let top = dropdownOffsetTop;
        if (dropdownOffsetTop - windowScrollTop + dropdownSettings.maxHeight > windowHeight) {
            if (inputWrapperRect.top >= dropdownSettings.maxHeight) {
                const dropdownRect = dropdownRef.current.getBoundingClientRect();
                top = inputWrapperRect.top - dropdownRect.height + windowScrollTop - dropdownSettings.gap;
            }
            else {
                height = windowHeight - dropdownOffsetTop - dropdownSettings.gap;
                if (height < dropdownSettings.minHeight) {
                    height = dropdownSettings.minHeight;
                }
            }
        }
        return {
            width: inputWrapperRect.width,
            top,
            left: inputWrapperRect.left,
            maxHeight: height,
            opacity: 1
        };
    }
    function scrollToHighlightedItem() {
        var _a, _b, _c, _d, _e;
        if (highlightedItemIndex < 0)
            return;
        const highlightedOption = (_a = dropdownRef.current) === null || _a === void 0 ? void 0 : _a.childNodes[highlightedItemIndex];
        if (highlightedOption == null)
            return;
        if (highlightedOption.offsetTop + highlightedOption.getBoundingClientRect().height > dropdownSettings.maxHeight) {
            (_b = dropdownRef.current) === null || _b === void 0 ? void 0 : _b.scroll(0, highlightedOption.offsetTop);
        }
        else if ((_d = (_c = dropdownRef.current) === null || _c === void 0 ? void 0 : _c.scrollTop) !== null && _d !== void 0 ? _d : highlightedOption.offsetTop <= 0) {
            (_e = dropdownRef.current) === null || _e === void 0 ? void 0 : _e.scroll(0, highlightedOption.offsetTop);
        }
    }
};
export default Dropdown;
