import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-unstable-nested-components */
import { useCallback, useEffect } from 'react';
import { setUser, removeFields, setAddressData } from '@store/slices/signUpSlice';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation, useGoToNextStep, useShowNextButton, useResetToStep } from '@hooks';
import MoreInfoButton from './components/MoreInfoButton';
import QuestionSection from '@components/QuestionSection';
import QuestionButtons from '@components/QuestionButtons';
import StepWrapper from '@components/StepWrapper';
import AddressAutoComplete from '@components/AddressAutoComplete';
import AdvisorPhone from './components/AdvisorPhone';
import { Steps, UserFields, RealEstateAdviceTypes, RealEstatePurchaseTypes, RealEstateSearchStatus, UserToUpdate } from '@constants';
const RealEstate = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [goToNextStep] = useGoToNextStep();
    const isNextButtonVisible = useShowNextButton(Steps.REAL_ESTATE);
    const [resetToStep] = useResetToStep();
    const { currentStep, firstUserInfo, completedSteps, moreInfo, showRealEstateLoan } = useSelector((state) => {
        var _a, _b, _c, _d;
        return ({
            currentStep: state.steps.currentStep,
            firstUserInfo: state.signUp.firstUser,
            completedSteps: state.steps.completedSteps,
            moreInfo: (_b = (_a = state.partner) === null || _a === void 0 ? void 0 : _a.partnerInfo) === null || _b === void 0 ? void 0 : _b.referenceMoreInfo,
            showRealEstateLoan: (_d = (_c = state.partner) === null || _c === void 0 ? void 0 : _c.partnerInfo) === null || _d === void 0 ? void 0 : _d.showRealEstateLoan
        });
    }, shallowEqual);
    const handleChangeField = useCallback(({ name, value, fieldsToReset = [], isMultiple = false }) => {
        var _a;
        if ([
            UserFields.IS_REAL_ESTATE_PLANNED,
            UserFields.REAL_ESTATE_ADVICE_TYPE,
            RealEstateAdviceTypes.FAMILY_TRANSFER,
            RealEstateAdviceTypes.BUYER_ADVICE,
            RealEstateAdviceTypes.OTHER
        ].includes(name)) {
            resetToStep(Steps.REAL_ESTATE);
        }
        if (fieldsToReset.length > 0) {
            dispatch(removeFields({
                fields: fieldsToReset,
                userType: UserToUpdate.BOTH
            }));
        }
        if (!isMultiple) {
            if (name === UserFields.REAL_ESTATE_PURCHASE_TYPE) {
                dispatch(setUser({ name, value: [value] }));
            }
            else {
                dispatch(setUser({ name, value }));
            }
        }
        else {
            const values = (_a = firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[name]) !== null && _a !== void 0 ? _a : [];
            if (Array.isArray(values)) {
                const updatedValues = values.includes(value) ? values.filter((v) => v !== value) : [...values, value];
                dispatch(setUser({ name, value: updatedValues }));
            }
        }
    }, [currentStep, completedSteps, firstUserInfo]);
    useEffect(() => {
        if (isNextButtonVisible) {
            goToNextStep();
        }
    }, [isNextButtonVisible]);
    useEffect(() => () => {
        dispatch(removeFields({
            fields: [
                UserFields.IS_REAL_ESTATE_PLANNED,
                UserFields.REAL_ESTATE_ADVICE_TYPE,
                UserFields.REAL_ESTATE_PURCHASE_TYPE,
                UserFields.REAL_ESTATE_ADDRESS,
                UserFields.REAL_ESTATE_LOAN_PROOF,
                UserFields.REAL_ESTATE_SEARCH_STATUS,
                UserFields.REAL_ESTATE_PURCHASE_AGREEMENT_SIGNED,
                UserFields.ADVISOR_PHONE
            ],
            userType: UserToUpdate.BOTH
        }));
        dispatch(setAddressData(null));
    }, []);
    const loadProofElement = showRealEstateLoan ? (_jsx(QuestionSection, { text: t('steps.realEstate.loan.title'), children: _jsx(QuestionButtons, { identifier: UserFields.REAL_ESTATE_LOAN_PROOF, buttons: [
                { text: t('steps.yes'), value: true },
                { text: t('steps.no'), value: false }
            ], selectedButtonValue: firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.REAL_ESTATE_LOAN_PROOF], onClick: (value) => handleChangeField({ name: UserFields.REAL_ESTATE_LOAN_PROOF, value }) }) })) : null;
    const addressElement = (_jsx(QuestionSection, { text: t('steps.realEstate.address.title'), children: _jsx(AddressAutoComplete, { name: "address", "data-testid": "real-estate-address", label: t('steps.realEstate.address.label'), onChange: (value) => {
                handleChangeField({ name: UserFields.REAL_ESTATE_ADDRESS, value });
                dispatch(setAddressData({ text: value, data: null }));
            }, onItemSelect: (address) => dispatch(setAddressData({ text: null, data: address })) }) }));
    const PurchaseTypes = ({ isMultiple = false }) => {
        var _a;
        return (_jsx(QuestionSection, { text: t('steps.realEstate.purchaseTypes.title'), children: _jsx(QuestionButtons, { identifier: UserFields.REAL_ESTATE_PURCHASE_TYPE, buttons: [
                    { text: t(`steps.realEstate.types.${RealEstatePurchaseTypes.VILLA}`), value: RealEstatePurchaseTypes.VILLA },
                    { text: t(`steps.realEstate.types.${RealEstatePurchaseTypes.OWNER}`), value: RealEstatePurchaseTypes.OWNER },
                    { text: t(`steps.realEstate.types.${RealEstatePurchaseTypes.SHARE}`), value: RealEstatePurchaseTypes.SHARE },
                    { text: t(`steps.realEstate.types.${RealEstatePurchaseTypes.HOLIDAY}`), value: RealEstatePurchaseTypes.HOLIDAY },
                    { text: t(`steps.realEstate.types.${RealEstatePurchaseTypes.LEISURE}`), value: RealEstatePurchaseTypes.LEISURE },
                    { text: t(`steps.realEstate.types.${RealEstatePurchaseTypes.PARENTAL}`), value: RealEstatePurchaseTypes.PARENTAL },
                    { text: t(`steps.realEstate.types.${RealEstatePurchaseTypes.ENTERPRISE}`), value: RealEstatePurchaseTypes.ENTERPRISE },
                    { text: t(`steps.realEstate.types.${RealEstatePurchaseTypes.COUNTRY}`), value: RealEstatePurchaseTypes.COUNTRY }
                ], selectedButtonValue: (_a = firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.REAL_ESTATE_PURCHASE_TYPE]) !== null && _a !== void 0 ? _a : [], onClick: (value) => handleChangeField({ name: UserFields.REAL_ESTATE_PURCHASE_TYPE, value, isMultiple }) }) }));
    };
    return (_jsxs(StepWrapper, { backgroundColor: "grey", currentStep: Steps.REAL_ESTATE, disableTopSpacing: true, children: [_jsx(QuestionSection, { text: t('steps.realEstate.planningToBuy.title'), children: _jsx(QuestionButtons, { identifier: UserFields.IS_REAL_ESTATE_PLANNED, buttons: [
                        { text: t('steps.yes'), value: true },
                        { text: t('steps.no'), value: false }
                    ], selectedButtonValue: firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.IS_REAL_ESTATE_PLANNED], onClick: (value) => {
                        dispatch(setAddressData(null));
                        handleChangeField({
                            name: UserFields.IS_REAL_ESTATE_PLANNED,
                            value,
                            fieldsToReset: [
                                UserFields.REAL_ESTATE_ADDRESS,
                                UserFields.REAL_ESTATE_LOAN_PROOF,
                                UserFields.REAL_ESTATE_PURCHASE_TYPE,
                                UserFields.REAL_ESTATE_ADVICE_TYPE,
                                UserFields.REAL_ESTATE_PURCHASE_AGREEMENT_SIGNED,
                                UserFields.REAL_ESTATE_SEARCH_STATUS,
                                UserFields.ADVISOR_PHONE
                            ]
                        });
                    } }) }), (firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.IS_REAL_ESTATE_PLANNED]) ? (_jsx(QuestionSection, Object.assign({ text: t('steps.realEstate.adviceType.title') }, (moreInfo != null ? { leftContent: _jsx(MoreInfoButton, { text: moreInfo }) } : {}), { children: _jsx(QuestionButtons, { identifier: UserFields.REAL_ESTATE_ADVICE_TYPE, buttons: [
                        { text: t(`steps.realEstate.adviceType.options.${RealEstateAdviceTypes.BUYER_ADVICE}`), value: RealEstateAdviceTypes.BUYER_ADVICE },
                        { text: t(`steps.realEstate.adviceType.options.${RealEstateAdviceTypes.FAMILY_TRANSFER}`), value: RealEstateAdviceTypes.FAMILY_TRANSFER },
                        { text: t(`steps.realEstate.adviceType.options.${RealEstateAdviceTypes.OTHER}`), value: RealEstateAdviceTypes.OTHER }
                    ], selectedButtonValue: firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.REAL_ESTATE_ADVICE_TYPE], onClick: (value) => {
                        dispatch(setAddressData(null));
                        handleChangeField({
                            name: UserFields.REAL_ESTATE_ADVICE_TYPE,
                            value,
                            fieldsToReset: [
                                UserFields.REAL_ESTATE_ADDRESS,
                                UserFields.REAL_ESTATE_LOAN_PROOF,
                                UserFields.REAL_ESTATE_PURCHASE_TYPE,
                                UserFields.REAL_ESTATE_PURCHASE_AGREEMENT_SIGNED,
                                UserFields.REAL_ESTATE_SEARCH_STATUS,
                                UserFields.ADVISOR_PHONE
                            ]
                        });
                    } }) }))) : null, (firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.REAL_ESTATE_ADVICE_TYPE]) === RealEstateAdviceTypes.FAMILY_TRANSFER ? (_jsxs(_Fragment, { children: [addressElement, _jsx(PurchaseTypes, {}), (firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.REAL_ESTATE_PURCHASE_TYPE]) != null ? loadProofElement : null] })) : null, (firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.REAL_ESTATE_ADVICE_TYPE]) === RealEstateAdviceTypes.BUYER_ADVICE ? (_jsxs(_Fragment, { children: [_jsx(QuestionSection, { text: t('steps.realEstate.searchStatus.title'), children: _jsx(QuestionButtons, { identifier: UserFields.REAL_ESTATE_SEARCH_STATUS, buttons: [
                                { text: t(`steps.realEstate.searchStatus.options.${RealEstateSearchStatus.SEARCHING}`), value: RealEstateSearchStatus.SEARCHING },
                                { text: t(`steps.realEstate.searchStatus.options.${RealEstateSearchStatus.FOUND}`), value: RealEstateSearchStatus.FOUND }
                            ], selectedButtonValue: firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.REAL_ESTATE_SEARCH_STATUS], onClick: (value) => {
                                dispatch(setAddressData(null));
                                handleChangeField({
                                    name: UserFields.REAL_ESTATE_SEARCH_STATUS,
                                    value,
                                    fieldsToReset: [
                                        UserFields.REAL_ESTATE_ADDRESS,
                                        UserFields.REAL_ESTATE_LOAN_PROOF,
                                        UserFields.REAL_ESTATE_PURCHASE_TYPE,
                                        UserFields.REAL_ESTATE_PURCHASE_AGREEMENT_SIGNED,
                                        UserFields.ADVISOR_PHONE
                                    ]
                                });
                            } }) }), (firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.REAL_ESTATE_SEARCH_STATUS]) === RealEstateSearchStatus.SEARCHING ? (_jsxs(_Fragment, { children: [loadProofElement, _jsx(PurchaseTypes, { isMultiple: true })] })) : null, (firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.REAL_ESTATE_SEARCH_STATUS]) === RealEstateSearchStatus.FOUND ? (_jsxs(_Fragment, { children: [_jsx(QuestionSection, { text: t('steps.realEstate.purchaseAgreementSigned.title'), children: _jsx(QuestionButtons, { identifier: UserFields.REAL_ESTATE_PURCHASE_AGREEMENT_SIGNED, buttons: [
                                        { text: t('steps.yes'), value: true, description: t('steps.realEstate.purchaseAgreementSigned.description.yes') },
                                        { text: t('steps.no'), value: false, description: t('steps.realEstate.purchaseAgreementSigned.description.no') }
                                    ], selectedButtonValue: firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.REAL_ESTATE_PURCHASE_AGREEMENT_SIGNED], onClick: (value) => handleChangeField({ name: UserFields.REAL_ESTATE_PURCHASE_AGREEMENT_SIGNED, value }) }) }), (firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.REAL_ESTATE_PURCHASE_AGREEMENT_SIGNED]) != null ? (_jsxs(_Fragment, { children: [addressElement, _jsx(PurchaseTypes, {}), (firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.REAL_ESTATE_PURCHASE_TYPE]) != null ? loadProofElement : null] })) : null] })) : null] })) : null, (firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.IS_REAL_ESTATE_PLANNED]) ? (_jsx(AdvisorPhone, { handleChangeField: handleChangeField, hasAssets: (firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo.assets) != null, userInfo: firstUserInfo })) : null] }));
};
export default RealEstate;
