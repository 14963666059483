import { appUrl } from '@constants';
import { Requester } from '@frontend-components/utils/requester';
export function createUserPost({ isAdvisor, partnerId, address_data, users, products, booking }) {
    return Requester.post(`${appUrl}/api/henvisning/${partnerId}`, {
        isAdvisor, users, address_data, products, booking
    });
}
export function createHubSpotTicket(content) {
    return Requester.post(`${appUrl}/api/user/create-ticket`, {
        subject: 'Fejl i henvisningsløsningen',
        content: content.join('\n')
    });
}
