import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@frontend-components/components/Button';
import Modal from '@frontend-components/components/Modal';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './MoreInfoButton.module.scss';
const MoreInfoButton = ({ text }) => {
    const { t } = useTranslation();
    const [isModalOpen, setModalOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: () => setModalOpen(true), kind: "text", icon: _jsx(FontAwesomeIcon, { icon: faInfoCircle }), className: styles.button, children: t('steps.realEstate.moreInfoCta') }), isModalOpen ? (_jsx(Modal, { onClose: () => setModalOpen(false), classNames: { modalContent: styles.modal }, children: _jsx("div", { dangerouslySetInnerHTML: { __html: text } }) })) : null] }));
};
export default MoreInfoButton;
