import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from '@hooks';
import styles from './PageTop.module.scss';
const PageTop = ({ partnerName, isAdvisor }) => {
    const { t } = useTranslation();
    const subTitleElement = isAdvisor ? (_jsx("p", { className: styles.subTitle, dangerouslySetInnerHTML: { __html: t('subTitleAdvisor') } })) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    _jsx(_Fragment, { children: partnerName != null ? _jsx("p", { "data-testid": "page-top-no-advisor-partner-text", className: styles.subTitle, dangerouslySetInnerHTML: { __html: t('subTitle', { partnerName }) } }) : null }));
    return (_jsxs("div", { className: `container ${styles.wrapper}`, children: [_jsx("h1", { className: styles.title, children: isAdvisor ? t('mainTitleAdvisor') : t('mainTitle') }), subTitleElement] }));
};
export default PageTop;
