import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useTranslation, useGoToNextStep, useShowNextButton, useResetToStep } from '@hooks';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { setUser, removeFields } from '@store/slices/signUpSlice';
import QuestionSection from '@components/QuestionSection';
import QuestionButtons from '@components/QuestionButtons';
import StepWrapper from '@components/StepWrapper';
import { UserFields, MaritalTypes, Steps } from '@constants';
import sharedStyles from '@css/sharedStyles.module.scss';
const Marital = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [goToNextStep] = useGoToNextStep();
    const isNextButtonVisible = useShowNextButton(Steps.MARITAL);
    const [resetToStep] = useResetToStep();
    const [fieldsToDisplay, setFieldsToDisplay] = useState([]);
    const { firstUserInfo, maritalSubtitle } = useSelector((state) => {
        var _a;
        return ({
            firstUserInfo: state.signUp.firstUser,
            maritalSubtitle: (_a = state.partner.partnerInfo) === null || _a === void 0 ? void 0 : _a.sectionQuestionsSubtext
        });
    }, shallowEqual);
    useEffect(() => {
        if (isNextButtonVisible) {
            goToNextStep();
        }
    }, [isNextButtonVisible]);
    const subtitleElement = maritalSubtitle != null ? _jsx("p", { className: sharedStyles.subtitle, dangerouslySetInnerHTML: { __html: maritalSubtitle } }) : null;
    let maritalButtons = [
        { text: t('steps.marital.marital.partnership'), value: MaritalTypes.MARITAL_RELATIONSHIP },
        { text: t('steps.marital.marital.married'), value: MaritalTypes.MARITAL_MARRIED }
    ];
    if (!(firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.PARTNER_PRESENT])) {
        maritalButtons = [...maritalButtons, {
                text: t('steps.marital.marital.single'),
                value: MaritalTypes.MARITAL_SINGLE
            }];
    }
    return (_jsxs(StepWrapper, { backgroundColor: "grey", currentStep: Steps.MARITAL, children: [subtitleElement, _jsx(QuestionSection, { text: t('steps.marital.marital.title'), children: _jsx(QuestionButtons, { identifier: UserFields.MARITAL, buttons: maritalButtons, selectedButtonValue: firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.MARITAL], onClick: (value) => handleChangeField(UserFields.MARITAL, value) }) }), fieldsToDisplay.includes(UserFields.RESIDENCE) ? (_jsx(QuestionSection, { text: t('steps.marital.residence.title'), children: _jsx(QuestionButtons, { identifier: UserFields.RESIDENCE, buttons: [
                        { text: t('steps.yes'), value: true },
                        { text: t('steps.no'), value: false }
                    ], selectedButtonValue: firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.RESIDENCE], onClick: (value) => handleChangeField(UserFields.RESIDENCE, value) }) })) : null, fieldsToDisplay.includes(UserFields.MARRIAGE) ? (_jsx(QuestionSection, { text: t('steps.marital.marriage.title'), children: _jsx(QuestionButtons, { identifier: UserFields.MARRIAGE, buttons: [
                        { text: t('steps.yes'), value: true },
                        { text: t('steps.no'), value: false }
                    ], selectedButtonValue: firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.MARRIAGE], onClick: (value) => handleChangeField(UserFields.MARRIAGE, value) }) })) : null, fieldsToDisplay.includes(UserFields.CHILDREN) ? (_jsx(QuestionSection, { text: (firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.MARITAL]) === MaritalTypes.MARITAL_SINGLE ? t('steps.marital.children.title') : t('steps.marital.children.childrenTogetherTitle'), children: _jsx(QuestionButtons, { identifier: UserFields.CHILDREN, buttons: [
                        { text: t('steps.yes'), value: true },
                        { text: t('steps.no'), value: false }
                    ], selectedButtonValue: firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.CHILDREN], onClick: (value) => handleChangeField(UserFields.CHILDREN, value) }) })) : null, fieldsToDisplay.includes(UserFields.CHILDREN_OUTSIDE_RELATIONSHIP) ? (_jsx(QuestionSection, { text: t('steps.marital.childrenOutsideRelationship.title'), children: _jsx(QuestionButtons, { identifier: UserFields.CHILDREN_OUTSIDE_RELATIONSHIP, buttons: [
                        { text: t('steps.yes'), value: true },
                        { text: t('steps.no'), value: false }
                    ], selectedButtonValue: firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.CHILDREN_OUTSIDE_RELATIONSHIP], onClick: (value) => handleChangeField(UserFields.CHILDREN_OUTSIDE_RELATIONSHIP, value) }) })) : null] }));
    function handleChangeField(name, value) {
        resetStepFields(name, fieldsToDisplay);
        setStepFieldsToDisplay(name, value, fieldsToDisplay);
        dispatch(setUser({ name, value }));
    }
    function setStepFieldsToDisplay(name, value, prevFields) {
        let nextFields = [];
        switch (name) {
            case UserFields.MARITAL:
                if (value === MaritalTypes.MARITAL_RELATIONSHIP) {
                    nextFields = [UserFields.RESIDENCE];
                }
                else {
                    nextFields = [UserFields.CHILDREN];
                }
                break;
            case UserFields.RESIDENCE:
                nextFields = [UserFields.MARRIAGE];
                break;
            case UserFields.MARRIAGE:
                nextFields = [UserFields.CHILDREN];
                break;
            case UserFields.CHILDREN:
                if ((firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.PARTNER_PRESENT]) || (firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.MARITAL]) !== MaritalTypes.MARITAL_SINGLE) {
                    nextFields = [UserFields.CHILDREN_OUTSIDE_RELATIONSHIP];
                }
                break;
            default:
                break;
        }
        setFieldsToDisplay([...Array.from(new Set([...prevFields, ...nextFields]))]);
    }
    function resetStepFields(targetField, prevFields) {
        const fieldsToIgnore = [UserFields.ASSETS, UserFields.CHILDREN, UserFields.CHILDREN_OUTSIDE_RELATIONSHIP];
        const fieldsToRemove = prevFields.splice(prevFields.indexOf(targetField) + 1).filter((field) => field !== targetField);
        if (fieldsToRemove.length > 0) {
            dispatch(removeFields({ fields: fieldsToRemove }));
        }
        if (!fieldsToIgnore.includes(targetField)) {
            resetToStep(Steps.MARITAL);
        }
    }
};
export default Marital;
