import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { setUser, setErrors } from '@store/slices/signUpSlice';
import { useTranslation, useGoToNextStep, useShowNextButton } from '@hooks';
import { validateUsersInfo } from '@utils';
import Button from '@frontend-components/components/Button';
import StepWrapper from '@components/StepWrapper';
import UserFormFields from './components/UserFormFields';
import { UserToUpdate, UserFields, Steps } from '@constants';
import styles from './UserDetails.module.scss';
import sharedStyles from '@css/sharedStyles.module.scss';
const UserDetails = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [goToNextStep] = useGoToNextStep();
    const isNextButtonVisible = useShowNextButton(Steps.USER_DETAILS);
    const { firstUserInfo, secondUserInfo, errors, partner, isAdvisor } = useSelector((store) => ({
        firstUserInfo: store.signUp.firstUser,
        secondUserInfo: store.signUp.secondUser,
        errors: store.signUp.errors,
        partner: store.partner.partnerInfo,
        isAdvisor: store.partner.isAdvisor
    }), shallowEqual);
    useEffect(() => {
        dispatch(setUser({ name: UserFields.PRIMARY_CONTACT, value: UserToUpdate.FIRST, userType: UserToUpdate.FIRST }));
    }, []);
    const isPartnerPresent = (firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.PARTNER_PRESENT]) != null && firstUserInfo[UserFields.PARTNER_PRESENT];
    // eslint-disable-next-line no-nested-ternary
    const titleText = isPartnerPresent ? t('steps.userInfo.titlePartnerPresent') : isAdvisor ? t('steps.userInfo.title') : t('steps.userInfo.titleNoAdvisor');
    // eslint-disable-next-line no-nested-ternary
    const subtitleText = isPartnerPresent ? t('steps.userInfo.subtitlePartnerPresent') : isAdvisor ? t('steps.userInfo.subtitle') : t('steps.userInfo.subtitleNoAdvisor');
    const nextButtonElement = isNextButtonVisible ? _jsx(Button, { type: "submit", "data-testid": `${Steps.USER_DETAILS}-next-button`, children: t('nextButtonText') }) : null;
    const secondUserFormElement = isPartnerPresent ? (_jsx(UserFormFields, { isRadioButtonChecked: (secondUserInfo === null || secondUserInfo === void 0 ? void 0 : secondUserInfo[UserFields.PRIMARY_CONTACT]) === UserToUpdate.SECOND, user: UserToUpdate.SECOND, errors: errors === null || errors === void 0 ? void 0 : errors[UserToUpdate.SECOND], isAdvisor: isAdvisor })) : null;
    const sectionSubText = (partner === null || partner === void 0 ? void 0 : partner.sectionClientSubtext) != null ? _jsx("p", { className: styles.sectionSubText, dangerouslySetInnerHTML: { __html: partner.sectionClientSubtext } }) : null;
    return (_jsxs(StepWrapper, { currentStep: Steps.USER_DETAILS, children: [_jsx("h2", { className: styles.title, children: titleText }), _jsx("p", { className: `${sharedStyles.subtitle} ${styles.subtitle}`, children: subtitleText }), _jsxs("form", { onSubmit: handleSubmitForm, className: styles.form, children: [sectionSubText, _jsx(UserFormFields, { isRadioButtonChecked: (firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.PRIMARY_CONTACT]) === UserToUpdate.FIRST, isRadioButtonVisible: isPartnerPresent, user: UserToUpdate.FIRST, errors: errors === null || errors === void 0 ? void 0 : errors[UserToUpdate.FIRST], isAdvisor: isAdvisor }), secondUserFormElement, nextButtonElement] })] }));
    function handleSubmitForm(event) {
        event.preventDefault();
        const formErrors = validateUsersInfo(firstUserInfo, secondUserInfo);
        if (Object.keys(formErrors).length > 0) {
            dispatch(setErrors(formErrors));
            return;
        }
        if (errors != null) {
            dispatch(setErrors());
        }
        goToNextStep();
    }
};
export default UserDetails;
