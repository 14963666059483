import { getServiceUrlFor } from '@frontend-components/utils/urls';
import { PurchaseTypes } from '@frontend-components/constants';
export const appUrl = process.env.APP_ENV !== 'production' ? process.env.APP_API_URL : getServiceUrlFor(window.location.href, 'app');
export const bookingUrl = process.env.APP_ENV !== 'production' ? process.env.BOOKING_API_URL : getServiceUrlFor(window.location.href, 'booking');
export const authHeaderName = 'X-AUTH-TOKEN';
window.urls = { applicationUrl: appUrl, bookingUrl };
export var UserToUpdate;
(function (UserToUpdate) {
    UserToUpdate["FIRST"] = "userFirst";
    UserToUpdate["SECOND"] = "userSecond";
    UserToUpdate["BOTH"] = "both";
})(UserToUpdate || (UserToUpdate = {}));
export var MeetingTypes;
(function (MeetingTypes) {
    MeetingTypes["PHONE"] = "phone";
    MeetingTypes["FACE_TO_FACE"] = "face_to_face";
})(MeetingTypes || (MeetingTypes = {}));
export var MaritalTypes;
(function (MaritalTypes) {
    MaritalTypes["MARITAL_SINGLE"] = "single";
    MaritalTypes["MARITAL_RELATIONSHIP"] = "partnersNotMarried";
    MaritalTypes["MARITAL_MARRIED"] = "married";
})(MaritalTypes || (MaritalTypes = {}));
export var AdditionalFields;
(function (AdditionalFields) {
    AdditionalFields["PRODUCTS"] = "products";
    AdditionalFields["BOOKING"] = "booking";
})(AdditionalFields || (AdditionalFields = {}));
export const AssetTypes = ['property', 'saving', 'pension', 'value'];
export var UserFields;
(function (UserFields) {
    UserFields["FIRST_NAME"] = "firstName";
    UserFields["LAST_NAME"] = "lastName";
    UserFields["EMAIL"] = "email";
    UserFields["PHONE"] = "phone";
    UserFields["ENABLED_DOCUMENTS_AND_TIME_BOOKING"] = "enabledDocumentsAndTimeBooking";
    UserFields["DOCUMENTS"] = "documents";
    UserFields["CONSENTS"] = "consents";
    UserFields["CONSENT_IDS"] = "consentIds";
    UserFields["COMMENT"] = "comment";
    UserFields["PARTNER_PRESENT"] = "partner_present";
    UserFields["PRIMARY_CONTACT"] = "primaryContact";
    UserFields["MEETING_TYPE"] = "meeting_medium";
    UserFields["SHARING_SCREEN"] = "sharing_screen";
    UserFields["ADVISOR_ID"] = "advisorId";
    UserFields["ADVISOR_NAME"] = "advisorName";
    UserFields["ADVISOR_PHONE"] = "advisorPhone";
    UserFields["BRANCH_ID"] = "branchId";
    UserFields["MARITAL"] = "maritalStatus";
    UserFields["ASSETS"] = "assets";
    UserFields["CHILDREN"] = "hasChildren";
    UserFields["CHILDREN_OUTSIDE_RELATIONSHIP"] = "hasChildrenOutsideRelationship";
    UserFields["MARRIAGE"] = "marriagePlanned";
    UserFields["RESIDENCE"] = "livingTogether";
    UserFields["IS_ADVISOR"] = "isAdvisor";
    UserFields["IS_REAL_ESTATE_PLANNED"] = "is_real_estate_purchase_planned";
    UserFields["REAL_ESTATE_ADVICE_TYPE"] = "real_estate_advice_type";
    UserFields["REAL_ESTATE_PURCHASE_TYPE"] = "real_estate_purchase_type";
    UserFields["REAL_ESTATE_ADDRESS"] = "real_estate_address";
    UserFields["REAL_ESTATE_LOAN_PROOF"] = "loan_proof";
    UserFields["REAL_ESTATE_SEARCH_STATUS"] = "real_estate_search_status";
    UserFields["REAL_ESTATE_PURCHASE_AGREEMENT_SIGNED"] = "real_estate_purchase_agreement_signed";
})(UserFields || (UserFields = {}));
export var Steps;
(function (Steps) {
    Steps["ADVISOR"] = "ADVISOR";
    Steps["USER_DETAILS"] = "USER_DETAILS";
    Steps["MARITAL"] = "MARITAL";
    Steps["REAL_ESTATE"] = "REAL_ESTATE";
    Steps["BOOKING"] = "BOOKING";
    Steps["ADDITIONAL_INFO"] = "ADDITIONAL_INFO";
    Steps["CONSENTS"] = "CONSENTS";
})(Steps || (Steps = {}));
export var RealEstatePurchaseTypes;
(function (RealEstatePurchaseTypes) {
    RealEstatePurchaseTypes["SHARE"] = "share";
    RealEstatePurchaseTypes["OWNER"] = "owner";
    RealEstatePurchaseTypes["ENTERPRISE"] = "enterprise";
    RealEstatePurchaseTypes["PARENTAL"] = "parental";
    RealEstatePurchaseTypes["HOLIDAY"] = "holiday";
    RealEstatePurchaseTypes["LEISURE"] = "leisure";
    RealEstatePurchaseTypes["COUNTRY"] = "country";
    RealEstatePurchaseTypes["VILLA"] = "villa";
})(RealEstatePurchaseTypes || (RealEstatePurchaseTypes = {}));
export var RealEstateAdviceTypes;
(function (RealEstateAdviceTypes) {
    RealEstateAdviceTypes["BUYER_ADVICE"] = "buyer_advice";
    RealEstateAdviceTypes["FAMILY_TRANSFER"] = "family_transfer";
    RealEstateAdviceTypes["OTHER"] = "other";
})(RealEstateAdviceTypes || (RealEstateAdviceTypes = {}));
export var RealEstateCallTimes;
(function (RealEstateCallTimes) {
    RealEstateCallTimes["ONE_DAY"] = "one_day";
    RealEstateCallTimes["THREE_DAYS"] = "three_days";
    RealEstateCallTimes["FIVE_DAYS"] = "five_days";
})(RealEstateCallTimes || (RealEstateCallTimes = {}));
export var RealEstateSearchStatus;
(function (RealEstateSearchStatus) {
    RealEstateSearchStatus["SEARCHING"] = "searching";
    RealEstateSearchStatus["FOUND"] = "found";
})(RealEstateSearchStatus || (RealEstateSearchStatus = {}));
export const stickyHeaderHeight = 74;
export const packageSupportedPurchaseTypes = [PurchaseTypes.digital_document_review, PurchaseTypes.digital_document_basis, PurchaseTypes.digital_document_advice];
