import { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { setCompletedSteps, setCurrentStep } from '@store/slices/stepsSlice';
import { setUser } from '@store/slices/signUpSlice';
import { selectIsRealEstateEnabled } from '@store/selectors/realEstateSelector';
import { Steps, UserFields, UserToUpdate } from '@constants';
export const useToggleEstateStep = () => {
    const dispatch = useDispatch();
    const { completedSteps, currentStep, isRealEstateEnabled } = useSelector((state) => ({
        completedSteps: state.steps.completedSteps,
        currentStep: state.steps.currentStep,
        isRealEstateEnabled: selectIsRealEstateEnabled(state)
    }), shallowEqual);
    useEffect(() => {
        if (!isRealEstateEnabled && completedSteps.includes(Steps.REAL_ESTATE) && currentStep !== Steps.MARITAL) {
            const newCompletedSteps = completedSteps.slice(0, completedSteps.indexOf(Steps.REAL_ESTATE));
            dispatch(setUser({ value: false, name: UserFields.IS_REAL_ESTATE_PLANNED, userType: UserToUpdate.BOTH }));
            dispatch(setCurrentStep(Steps.MARITAL));
            dispatch(setCompletedSteps(newCompletedSteps));
            return;
        }
        if (isRealEstateEnabled
            && !completedSteps.includes(Steps.REAL_ESTATE)
            && completedSteps.includes(Steps.MARITAL)
            && currentStep !== Steps.MARITAL
            && (completedSteps.indexOf(Steps.MARITAL) + 1) < completedSteps.length) {
            const newCompletedSteps = completedSteps.slice(0, completedSteps.indexOf(Steps.MARITAL) + 1);
            dispatch(setCurrentStep(Steps.MARITAL));
            dispatch(setCompletedSteps(newCompletedSteps));
        }
    }, [isRealEstateEnabled, currentStep, completedSteps]);
};
