export const mapAddressResponse = (addressArray) => addressArray.map((addressObject) => ({
    external_id: addressObject.id,
    street_address: addressObject.adresseringsvejnavn,
    house_number: addressObject.husnr,
    floor: addressObject.etage,
    door: addressObject.dør,
    postal_code: addressObject.postnr,
    address_city: addressObject.postnrnavn,
    full_address: addressObject.betegnelse
}));
