import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { setConsents } from '@store/slices/signUpSlice';
import { useTranslation } from '@hooks';
import SubmitButton from './components/SubmitButton';
import Checkbox from '@frontend-components/components/Checkbox';
import QuestionSection from '@components/QuestionSection';
import QuestionButtons from '@components/QuestionButtons';
import StepWrapper from '@components/StepWrapper';
import { MeetingTypes, UserFields, Steps } from '@constants';
import sharedStyles from '@css/sharedStyles.module.scss';
import styles from './Consents.module.scss';
const Consents = () => {
    var _a;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { firstUserInfo, partner, errors, partnerName, consents } = useSelector((store) => {
        var _a;
        return ({
            firstUserInfo: store.signUp.firstUser,
            partner: store.partner.partnerInfo,
            errors: store.signUp.errors,
            partnerName: store.partner.partnerName,
            consents: (_a = store.signUp.selectedConsents) !== null && _a !== void 0 ? _a : []
        });
    }, shallowEqual);
    useEffect(() => {
        var _a, _b, _c, _d;
        if ((partner === null || partner === void 0 ? void 0 : partner.consent) == null) {
            return;
        }
        const availableConsents = Array.isArray((_a = partner === null || partner === void 0 ? void 0 : partner.consent) === null || _a === void 0 ? void 0 : _a.consents) ? (_b = partner === null || partner === void 0 ? void 0 : partner.consent) === null || _b === void 0 ? void 0 : _b.consents : Object.values((_d = (_c = partner === null || partner === void 0 ? void 0 : partner.consent) === null || _c === void 0 ? void 0 : _c.consents) !== null && _d !== void 0 ? _d : {});
        const consentsToRender = availableConsents.filter((consent) => consent.visible.includes('SignupForm'));
        dispatch(setConsents(consentsToRender));
    }, [dispatch, partner]);
    const consentElements = consents.filter((consent) => {
        if (!(firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.ENABLED_DOCUMENTS_AND_TIME_BOOKING])) {
            return consent;
        }
        return !consent.options.some((option) => ['8-12', '12-17', '17-21'].includes(option.text));
    }).map((consent) => {
        if (consent.inputType === 'radio') {
            let buttons = [];
            consent.options.forEach((option) => {
                buttons = [...buttons, { text: option.text, value: option.consentType.join(',') }];
            });
            return (_jsx(QuestionSection, { text: consent.text, errorMessage: errors === null || errors === void 0 ? void 0 : errors[consent.id], children: _jsx(QuestionButtons, { identifier: consent.id, buttons: buttons, selectedButtonValue: consent === null || consent === void 0 ? void 0 : consent.value, onClick: (value) => handleClickConsents(true, consent.id, value) }) }, consent.id));
        }
        return (_jsx("div", { className: styles.checkboxWrapper, children: _jsx(Checkbox, { classNames: { labelText: styles.labelText }, name: consent.id, label: consent.text, errorMessage: errors === null || errors === void 0 ? void 0 : errors[consent.id], handleOnChange: () => handleClickConsents(!(consent === null || consent === void 0 ? void 0 : consent.accepted), consent.id) }) }, consent.id));
    });
    return (_jsxs(StepWrapper, { backgroundColor: "grey", currentStep: Steps.CONSENTS, children: [_jsx("p", { className: styles.title, dangerouslySetInnerHTML: { __html: (_a = t('steps.consents.title')) !== null && _a !== void 0 ? _a : '' } }), _jsx("p", { className: sharedStyles.subtitle, dangerouslySetInnerHTML: { __html: t(`steps.consents.subtitle.${getMeetingType()}`) } }), consentElements, partnerName !== 'al' ? (_jsx("p", { className: `${sharedStyles.text} ${styles.bottomText}`, dangerouslySetInnerHTML: { __html: t('steps.consents.bottomText') } })) : null, _jsx("div", { className: sharedStyles.nextButtonWrapper, children: _jsx(SubmitButton, {}) })] }));
    function getMeetingType() {
        const remote = 'remote';
        const faceToFace = 'faceToFace';
        if (!(firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.IS_ADVISOR])) {
            return remote;
        }
        if (partner === null || partner === void 0 ? void 0 : partner.isSharedScreenQuestionActive) {
            if (firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.SHARING_SCREEN]) {
                return remote;
            }
            return faceToFace;
        }
        if (firstUserInfo[UserFields.MEETING_TYPE] === MeetingTypes.FACE_TO_FACE) {
            return remote;
        }
        return faceToFace;
    }
    function handleClickConsents(accepted, id, value) {
        const updatedConsents = consents.map((consent) => {
            if (consent.id === id) {
                return Object.assign(Object.assign({}, consent), { accepted, value: value !== null && value !== void 0 ? value : id });
            }
            return consent;
        });
        dispatch(setConsents(updatedConsents));
    }
};
export default Consents;
