var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { getMonthInfoFromDate, getMonthDisabledDays, getCurrentDateTimeSlots, getNextMonthDaysInfo, getWeekDayTimeSlotDate, getAllDatesOfTheMonth } from '../TimeBooking.utils';
import Message from '../../../components/Message';
import Spinner from '../../../components/Spinner';
import DayPickerComponent from '../../../components/DayPicker';
import TimeSlots from './components/TimeSlots';
import styles from './DayPicker.module.scss';
const DayPicker = ({ selectedTimeSlot, onChangeTimeSlot, isTimeSlotsLoading, onChangeTimeSlotsLoading, fetchTimeSlotsPromise, classNames = { wrapper: null, wrapperCenter: null }, text }) => {
    const { t } = useTranslation();
    const [timeSlots, setTimeSlots] = useState({});
    const [isError, setError] = useState(false);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const selectedMonth = selectedTimePeriod == null ? new Date() : new Date(`${selectedTimePeriod.month}/${selectedTimePeriod.startDate}/${selectedTimePeriod.year}`);
    const selectedDayTimeSlots = getSelectedDayTimeSlots();
    const disabledDays = isTimeSlotsLoading ? getAllDatesOfTheMonth(selectedTimePeriod === null || selectedTimePeriod === void 0 ? void 0 : selectedTimePeriod.year, selectedTimePeriod === null || selectedTimePeriod === void 0 ? void 0 : selectedTimePeriod.month) : getDisabledDays();
    useEffect(() => {
        setTimePeriod();
    }, []);
    useEffect(() => {
        if (selectedTimePeriod == null) {
            return;
        }
        fetchTimeSlots();
    }, [selectedTimePeriod]);
    const textElement = text != null ? _jsx("p", { className: "font-medium", children: text }) : null;
    const timeSlotsElement = !isTimeSlotsLoading && !isError ? (_jsx(TimeSlots, { timeSlots: selectedDayTimeSlots, selectedTimeSlot: selectedTimeSlot, onChangeTimeSlot: onChangeTimeSlot })) : null;
    const spinnerElement = isTimeSlotsLoading ? _jsx("div", { className: styles.spinnerWrapper, children: _jsx(Spinner, { size: "small", position: "center" }) }) : null;
    const errorElement = isError ? _jsx(Message, { className: styles.errorWrapper, type: "error", children: t('global.errors.genericError') }) : null;
    return (_jsxs("div", { className: classnames(styles.wrapper, classNames.wrapper), children: [textElement, _jsxs("div", { className: classnames(styles.wrapperCenter, classNames.wrapperCenter), children: [_jsx(DayPickerComponent, { selected: selectedDate, disabled: disabledDays, onDayClick: setSelectedDate, onMonthChange: setTimePeriod, fromMonth: new Date(), month: selectedMonth }), timeSlotsElement, spinnerElement, errorElement] })] }));
    function fetchTimeSlots() {
        return __awaiter(this, void 0, void 0, function* () {
            if (selectedTimePeriod == null || isTimeSlotsLoading) {
                return;
            }
            const { year, month, startDate, endDate } = selectedTimePeriod;
            const validMonth = month === 0 ? 1 : month;
            if (timeSlots[`${validMonth}-${year}`] == null) {
                if (!isTimeSlotsLoading && onChangeTimeSlotsLoading != null) {
                    onChangeTimeSlotsLoading(true);
                }
                const from = `${startDate}-${validMonth}-${year}`;
                const to = `${endDate}-${validMonth}-${year}`;
                try {
                    const data = yield fetchTimeSlotsPromise(from, to);
                    if (onChangeTimeSlotsLoading != null)
                        onChangeTimeSlotsLoading(false);
                    if (data != null && data.length > 0) {
                        setTimeSlots(Object.assign(Object.assign({}, timeSlots), { [`${validMonth}-${year}`]: data }));
                        setSelectedDate(getWeekDayTimeSlotDate(data));
                    }
                    else if (Object.keys(timeSlots).length === 0) {
                        setSelectedTimePeriod(getNextMonthDaysInfo(validMonth, year));
                    }
                }
                catch (_a) {
                    setError(true);
                    if (onChangeTimeSlotsLoading != null)
                        onChangeTimeSlotsLoading(false);
                }
            }
        });
    }
    function setTimePeriod(date = new Date()) {
        const { month, year, startDate, endDate } = getMonthInfoFromDate(date);
        const bookings = timeSlots[`${month}-${year}`];
        if (bookings != null) {
            setSelectedDate(getWeekDayTimeSlotDate(bookings));
        }
        setSelectedTimePeriod({
            month, year, startDate, endDate
        });
    }
    function getSelectedDayTimeSlots() {
        if (selectedTimePeriod == null) {
            return [];
        }
        const { month, year } = selectedTimePeriod;
        if (selectedTimePeriod == null || timeSlots[`${month}-${year}`] == null) {
            return [];
        }
        return getCurrentDateTimeSlots(Object.values(timeSlots[`${month}-${year}`]), selectedDate);
    }
    function getDisabledDays() {
        if (selectedTimePeriod == null) {
            return [];
        }
        const { year, month } = selectedTimePeriod;
        const selectedPeriodBookings = timeSlots[`${month}-${year}`];
        if (selectedPeriodBookings == null) {
            return [];
        }
        return getMonthDisabledDays(selectedPeriodBookings, month, year);
    }
};
export default DayPicker;
