import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { setDocuments } from '@store/slices/signUpSlice';
import { useTranslation } from '@hooks';
import DocumentLine from './components/DocumentLine';
import QuestionSection from '@components/QuestionSection';
import Button from '@frontend-components/components/Button';
import { Steps } from '@constants';
import sharedStyles from '@css/sharedStyles.module.scss';
import { PurchaseTypes, digitalProducts } from '@frontend-components/constants';
const DocumentSelector = ({ onNextButtonVisibilityChange }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const changeStepTimeout = useRef();
    const [isNextButtonVisible, setNextButtonVisible] = useState(true);
    const { documents = [] } = useSelector((store) => ({ documents: store.signUp.documents }), shallowEqual);
    useEffect(() => {
        documentsInit();
    }, []);
    useEffect(() => () => {
        clearTimeout(changeStepTimeout.current);
    }, []);
    const documentsElement = documents.map((document, key) => (_jsx(DocumentLine, { documentInfo: document, isTitleVisible: key === 0, onChange: handleChangeDocument, onDelete: handleDeleteDocument }, document.id)));
    const nextButtonElement = isNextButtonVisible ? (_jsx("div", { className: sharedStyles.nextButtonWrapper, children: _jsx(Button, { onClick: handleClickNext, disabled: documents == null, "data-testid": `${Steps.BOOKING}-next-button`, children: t('nextButtonText') }) })) : null;
    return (_jsxs(_Fragment, { children: [_jsxs(QuestionSection, { text: t('steps.documents.title'), children: [documentsElement, _jsx(Button, { kind: "text", "data-testid": "add-document-button", onClick: handleAddDocument, children: t('steps.documents.addProduct') })] }), nextButtonElement] }));
    function documentsInit() {
        let documentItems = [];
        documentItems = [...documentItems, {
                id: 'document-1',
                purchase_type: PurchaseTypes.digital_document_advice,
                value: digitalProducts[0],
                canDelete: false
            }];
        dispatch(setDocuments(documentItems));
    }
    function handleChangeDocument(value, name, id) {
        if (documents == null || value == null || value.length === 0)
            return;
        dispatch(setDocuments(documents.map((document) => {
            let updatedDocument = Object.assign({}, document);
            if (id === updatedDocument.id) {
                updatedDocument = Object.assign(Object.assign({}, updatedDocument), { [name]: value });
            }
            return updatedDocument;
        })));
        if (!isNextButtonVisible) {
            setNextButtonVisible(true);
            onNextButtonVisibilityChange(false);
        }
    }
    function handleDeleteDocument(id) {
        if (documents == null)
            return;
        const filteredDocuments = [...documents].filter((document) => document.id !== id);
        dispatch(setDocuments(filteredDocuments));
    }
    function handleClickNext() {
        if (documents == null)
            return;
        setNextButtonVisible(false);
        onNextButtonVisibilityChange(true);
    }
    function handleAddDocument() {
        if (documents == null)
            return;
        dispatch(setDocuments([...documents, {
                id: `document-${documents.length + 1}`,
                purchase_type: PurchaseTypes.digital_document_advice,
                value: digitalProducts[0],
                canDelete: true
            }]));
        if (!isNextButtonVisible) {
            setNextButtonVisible(true);
            onNextButtonVisibilityChange(false);
        }
    }
};
export default DocumentSelector;
