import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { setUser, removeFields, setTimeSlot, setDocuments } from '@store/slices/signUpSlice';
import { useTranslation, useGoToNextStep, useShowNextButton, useResetToStep } from '@hooks';
import DocumentSelector from './components/DocumentSelector';
import TimeBooking from './components/TimeBooking';
import QuestionSection from '@components/QuestionSection';
import QuestionButtons from '@components/QuestionButtons';
import StepWrapper from '@components/StepWrapper';
import { Steps, UserFields, UserToUpdate } from '@constants';
import sharedStyles from '@css/sharedStyles.module.scss';
import styles from './Booking.module.scss';
const Booking = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [goToNextStep] = useGoToNextStep();
    const isNextButtonVisible = useShowNextButton(Steps.BOOKING);
    const [resetToStep] = useResetToStep();
    const [isTimeBookingVisible, setTimeBookingVisible] = useState(false);
    const { firstUserInfo, currentStep } = useSelector((state) => ({ firstUserInfo: state.signUp.firstUser, currentStep: state.steps.currentStep }), shallowEqual);
    const handleClickEnableBooking = (value) => {
        if (currentStep !== Steps.BOOKING && value) {
            resetToStep(Steps.BOOKING);
        }
        dispatch(setUser({ name: UserFields.ENABLED_DOCUMENTS_AND_TIME_BOOKING, value }));
    };
    useEffect(() => {
        if (isNextButtonVisible) {
            goToNextStep();
        }
    }, [isNextButtonVisible]);
    useEffect(() => () => {
        dispatch(setTimeSlot(undefined));
        dispatch(setDocuments([]));
        dispatch(removeFields({ fields: [UserFields.ENABLED_DOCUMENTS_AND_TIME_BOOKING], userType: UserToUpdate.BOTH }));
    }, []);
    const documentSelectorElement = (firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.ENABLED_DOCUMENTS_AND_TIME_BOOKING]) ? _jsx(DocumentSelector, { onNextButtonVisibilityChange: setTimeBookingVisible }) : null;
    const timeBookingElement = (firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.ENABLED_DOCUMENTS_AND_TIME_BOOKING]) && isTimeBookingVisible ? _jsx(TimeBooking, {}) : null;
    return (_jsxs(StepWrapper, { backgroundColor: "grey", currentStep: Steps.BOOKING, disableTopSpacing: true, children: [_jsxs(QuestionSection, { text: t('steps.marital.booking.title'), children: [_jsxs("div", { className: styles.textWrapper, children: [_jsx("p", { className: sharedStyles.text, children: t('steps.marital.booking.text1') }), _jsx("p", { className: sharedStyles.text, children: t('steps.marital.booking.text2') })] }), _jsx(QuestionButtons, { identifier: UserFields.ENABLED_DOCUMENTS_AND_TIME_BOOKING, selectedButtonValue: firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.ENABLED_DOCUMENTS_AND_TIME_BOOKING], buttons: [
                            { text: t('steps.marital.booking.bookNow'), value: true },
                            { text: t('steps.marital.booking.bookLater'), value: false }
                        ], onClick: (value) => handleClickEnableBooking(value) })] }), documentSelectorElement, timeBookingElement] }));
};
export default Booking;
