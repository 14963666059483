export function getCurrentDateTimeSlots(timeSlots, date) {
    return timeSlots.filter((slot) => {
        const bookingDate = new Date(slot.date);
        return bookingDate.getMonth() === date.getMonth() && bookingDate.getDate() === date.getDate();
    });
}
export function getLabelForTimeSlot(start, end) {
    const dateStart = new Date(start);
    const dateEnd = new Date(end);
    const timeStart = dateStart.toLocaleTimeString('DK-dk', { hour: '2-digit', minute: '2-digit', timeZone: 'Etc/GMT-2' });
    const timeEnd = dateEnd.toLocaleTimeString('DK-dk', { hour: '2-digit', minute: '2-digit', timeZone: 'Etc/GMT-2' });
    return `Kl. ${timeStart} - ${timeEnd}`;
}
export function getTimeLabelForTitle(date) {
    const dateStart = new Date(date);
    const timeStart = dateStart.toLocaleTimeString('DK-dk', { hour: '2-digit', minute: '2-digit', timeZone: 'Etc/GMT-2' });
    return `${dateStart.getDate()}/${dateStart.getMonth() + 1} kl. ${timeStart}`;
}
export function isDateGreaterThanToday(date) {
    const today = new Date();
    date.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    return date.getTime() > today.getTime();
}
export function isBookingDateDisabled(timeSlots, date) {
    if (!isDateGreaterThanToday(date)) {
        return true;
    }
    const providedDayBookings = timeSlots.filter((slot) => {
        const bookingStartDate = new Date(slot.date);
        return bookingStartDate.getDate() === date.getDate() && bookingStartDate.getMonth() === date.getMonth() && bookingStartDate.getFullYear() === date.getFullYear() && ![0, 6].includes(date.getDay());
    });
    return providedDayBookings.length === 0;
}
export function getMonthInfoFromDate(date = new Date()) {
    const now = new Date();
    date.setHours(0, 0, 0, 0);
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    // eslint-disable-next-line no-nested-ternary
    let startDate = month > now.getMonth() + 1 ? 1 : isDateGreaterThanToday(date) ? date.getDate() : date.getDate() + 1;
    let numberOfDaysInMonth = new Date(year, month, 0).getDate();
    if (startDate > numberOfDaysInMonth) {
        month += 1;
        if (month > 12) {
            month = 0;
            year += 1;
        }
        numberOfDaysInMonth = new Date(year, month, 0).getDate();
        startDate = 1;
    }
    return {
        month,
        year,
        startDate,
        endDate: numberOfDaysInMonth
    };
}
export function getNextMonthDaysInfo(month, year) {
    let nextMonth = month + 1;
    let newYear = year;
    let newEndDate = new Date(year, nextMonth - 1, 0).getDate();
    if (nextMonth > 12) {
        newYear += 1;
        nextMonth = 1;
        newEndDate = new Date(newYear, nextMonth - 1, 0).getDate();
    }
    return {
        month: nextMonth,
        year: newYear,
        startDate: 1,
        endDate: newEndDate
    };
}
export function getMonthDisabledDays(timeSlots, month, year) {
    let disabledDays = [];
    const numberOfDaysInMonth = new Date(year, month, 0).getDate();
    for (let day = 1; day <= numberOfDaysInMonth; day += 1) {
        const date = new Date(year, month - 1, day);
        if (isBookingDateDisabled(timeSlots, date)) {
            disabledDays = [...disabledDays, date];
        }
    }
    return disabledDays;
}
export function getAllDatesOfTheMonth(year, month) {
    let dates = [];
    const now = new Date();
    const currentMonth = month == null ? now.getMonth() : month - 1;
    const currentYear = year == null ? now.getFullYear() : year;
    const numberOfDaysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    for (let day = 0; day <= numberOfDaysInMonth; day += 1) {
        const date = new Date(currentYear, currentMonth, day);
        dates = [...dates, date];
    }
    return dates;
}
export function getWeekDayTimeSlotDate(timeSlots) {
    const weekDayTimeSlot = timeSlots.find((slot) => {
        const timesSlotStartDate = new Date(slot.date);
        return ![0, 6].includes(timesSlotStartDate.getDay()) && isDateGreaterThanToday(timesSlotStartDate);
    });
    return weekDayTimeSlot != null ? new Date(weekDayTimeSlot.date) : new Date();
}
