import { useCallback } from 'react';
import { useTranslation as i18UseTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
export function useTranslation() {
    const i18n = i18UseTranslation();
    const { translations } = useSelector((store) => { var _a; return ({ translations: (_a = store.partner.partnerInfo) === null || _a === void 0 ? void 0 : _a.translations }); }, shallowEqual);
    const t = useCallback((key, options) => {
        let translation = '';
        if (translations != null && key in translations) {
            translation = translations[key];
        }
        else {
            translation = i18n.t(key);
        }
        if (options != null && Object.keys(options).length > 0) {
            Object.keys(options).forEach((option) => {
                if (options[option] != null) {
                    translation = translation.replace(`{${option}}`, options[option]);
                }
            });
        }
        return translation;
    }, [translations]);
    return { t };
}
