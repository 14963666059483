import { useLayoutEffect, useRef } from 'react';
import { stickyHeaderHeight } from '@constants';
export function useScrollPage(ref, wrapperHeight, direction = 'down', skip = false) {
    const firstMount = useRef(false);
    useLayoutEffect(() => {
        if (ref.current == null || skip || wrapperHeight === 0) {
            return;
        }
        if (!firstMount.current) {
            firstMount.current = true;
            return;
        }
        const rects = ref.current.getBoundingClientRect();
        const { scrollY } = window;
        const { y, height } = rects;
        window.scrollTo({
            top: direction === 'down' ? y + height + scrollY : y + scrollY - stickyHeaderHeight,
            behavior: 'smooth'
        });
    }, [wrapperHeight, skip]);
}
