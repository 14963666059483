import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import Message from '@frontend-components/components/Message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@frontend-components/components/Tooltip';
import styles from './QuestionSection.module.scss';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
const QuestionSection = ({ text, info, children, errorMessage, leftContent }) => {
    const infoRef = useRef(null);
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    return (_jsxs("div", { className: styles.wrapper, children: [_jsxs("div", { className: styles.textWrapper, children: [_jsxs("div", { className: styles.textWithInfo, children: [_jsx("p", { className: styles.text, dangerouslySetInnerHTML: { __html: text } }), info ? (_jsxs(_Fragment, { children: [_jsx(FontAwesomeIcon, { className: styles.infoIcon, onPointerEnter: () => setIsInfoVisible(true), onPointerLeave: () => setIsInfoVisible(false), ref: infoRef, icon: faInfoCircle }), isInfoVisible ? (_jsx(Tooltip, { parentRef: infoRef, position: "top-right", children: info })) : null] })) : null] }), errorMessage != null && errorMessage.length > 0 ? _jsx(Message, { type: "error", children: errorMessage }) : null, leftContent != null ? leftContent : null] }), _jsx("div", { className: styles.content, children: children })] }));
};
export default QuestionSection;
