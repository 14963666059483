import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import global from '@frontend-components/public/global-translations.json';
import da from '@public/locales/da.json';
const resources = { da: { translation: Object.assign(Object.assign({}, da), global) } };
i18n.use(initReactI18next).init({
    lng: 'da',
    fallbackLng: 'da',
    interpolation: { escapeValue: false },
    resources
});
export default i18n;
