import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QuestionSection from '@components/QuestionSection';
import Input from '@frontend-components/components/Input';
import { validateDanishPhoneNumber } from '@frontend-components/utils/validation';
import { RealEstateAdviceTypes, UserFields } from '@constants';
import { useSelector } from 'react-redux';
const AdvisorPhone = ({ handleChangeField, hasAssets, userInfo }) => {
    var _a;
    const [isAdvisorPhoneValid, setIsAdvisorPhoneValid] = useState(null);
    const { t } = useTranslation();
    const { isAdvisor, isLoanProof } = useSelector(({ partner }) => {
        var _a;
        return ({
            isAdvisor: partner.isAdvisor,
            isLoanProof: (_a = partner.partnerInfo) === null || _a === void 0 ? void 0 : _a.showRealEstateLoan
        });
    });
    if (!isAdvisor) {
        return null;
    }
    const handleChange = (event) => {
        const { isValid } = validateDanishPhoneNumber(event.target.value);
        if (isValid) {
            handleChangeField({ name: UserFields.ADVISOR_PHONE, value: event.target.value });
            setIsAdvisorPhoneValid(true);
        }
        else {
            setIsAdvisorPhoneValid(false);
            handleChangeField({ name: UserFields.ADVISOR_PHONE, value: null, fieldsToReset: [UserFields.ADVISOR_PHONE] });
        }
    };
    if (hasAssets && isAdvisorPhoneValid === null) {
        setIsAdvisorPhoneValid(false);
    }
    if ((userInfo === null || userInfo === void 0 ? void 0 : userInfo[UserFields.REAL_ESTATE_ADVICE_TYPE]) == null) {
        return null;
    }
    if ((userInfo === null || userInfo === void 0 ? void 0 : userInfo[UserFields.REAL_ESTATE_ADVICE_TYPE]) === RealEstateAdviceTypes.BUYER_ADVICE) {
        if ((userInfo === null || userInfo === void 0 ? void 0 : userInfo[UserFields.REAL_ESTATE_PURCHASE_TYPE]) == null) {
            return null;
        }
    }
    if ((userInfo === null || userInfo === void 0 ? void 0 : userInfo[UserFields.REAL_ESTATE_ADVICE_TYPE]) === RealEstateAdviceTypes.FAMILY_TRANSFER) {
        if (isLoanProof && (userInfo === null || userInfo === void 0 ? void 0 : userInfo[UserFields.REAL_ESTATE_LOAN_PROOF]) == null) {
            return null;
        }
    }
    return (_jsx(QuestionSection, { text: t('steps.realEstate.advisorPhone.text'), info: (_a = t('steps.realEstate.advisorPhone.info')) !== null && _a !== void 0 ? _a : undefined, children: _jsx(Input, { name: "advisor_phone", label: t('steps.realEstate.advisorPhone.label'), onChange: handleChange, isError: isAdvisorPhoneValid === false, errorMessage: isAdvisorPhoneValid === false ? t('steps.realEstate.advisorPhone.error') : null, "data-testid": `${UserFields.ADVISOR_PHONE}-input` }) }));
};
export default AdvisorPhone;
