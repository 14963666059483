var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { createUserPost } from '@api/signUp';
const initialState = {
    firstUser: undefined,
    secondUser: undefined,
    documents: undefined,
    addressData: null,
    timeSlot: undefined,
    isSignUpLoading: false,
    isSignUpSuccess: false,
    isSignUpFailure: false,
    selectedConsents: undefined,
    errors: undefined
};
const signUpSlice = createSlice({
    name: 'signUp',
    initialState,
    reducers: {
        setUser(state, action) {
            const { userType = 'both', name, value } = action.payload;
            if (userType === 'both') {
                state.firstUser = Object.assign(Object.assign({}, state.firstUser), { [name]: value });
                state.secondUser = Object.assign(Object.assign({}, state.secondUser), { [name]: value });
            }
            else if (userType === 'userFirst') {
                state.firstUser = Object.assign(Object.assign({}, state.firstUser), { [name]: value });
            }
            else {
                state.secondUser = Object.assign(Object.assign({}, state.secondUser), { [name]: value });
            }
        },
        removeFields(state, action) {
            const { userType = 'both', fields } = action.payload;
            const removeUserFields = (user) => {
                let newFields = {};
                Object.keys(user).forEach((key) => {
                    if (!fields.includes(key)) {
                        newFields = Object.assign(Object.assign({}, newFields), { [key]: user[key] });
                    }
                });
                return newFields;
            };
            if (userType === 'both') {
                state.firstUser = removeUserFields(state.firstUser);
                state.secondUser = removeUserFields(state.secondUser);
            }
            else if (userType === 'userFirst') {
                state.firstUser = removeUserFields(state.firstUser);
            }
            else {
                state.secondUser = removeUserFields(state.secondUser);
            }
        },
        setSignUpLoading(state) {
            state.isSignUpLoading = true;
        },
        setSignUpSuccess(state, action) {
            state.isSignUpLoading = false;
            state.isSignUpSuccess = action.payload;
        },
        setSignUpFailure(state, action) {
            state.isSignUpLoading = false;
            state.isSignUpFailure = action.payload;
        },
        setConsents(state, action) {
            state.selectedConsents = action.payload;
        },
        setDocuments(state, action) {
            state.documents = action.payload;
        },
        setAddressData(state, action) {
            state.addressData = action.payload;
        },
        setTimeSlot(state, action) {
            state.timeSlot = action.payload;
        },
        setErrors(state, action) {
            state.errors = action.payload;
        },
        resetStore() {
            return initialState;
        }
    }
});
export const { setUser, setTimeSlot, setSignUpLoading, setSignUpSuccess, setSignUpFailure, removeFields, setDocuments, setAddressData, setErrors, resetStore, setConsents } = signUpSlice.actions;
export default signUpSlice.reducer;
export const createUser = (params) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        dispatch(setSignUpLoading());
        yield createUserPost(params);
        dispatch(setSignUpSuccess(true));
    }
    catch (_a) {
        dispatch(setSignUpFailure(true));
    }
});
