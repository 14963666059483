var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useRef, useEffect } from 'react';
import classnames from 'classnames';
import { useScrollPage } from '@hooks';
import { useElementSize } from '@frontend-components/hooks';
import { useSelector, shallowEqual } from 'react-redux';
import styles from './StepWrapper.module.scss';
import { stickyHeaderHeight } from '@constants';
const StepWrapper = (_a) => {
    var { scrollOnMount = true, currentStep, backgroundColor = 'white', className, children, disableTopSpacing = false } = _a, rest = __rest(_a, ["scrollOnMount", "currentStep", "backgroundColor", "className", "children", "disableTopSpacing"]);
    const wrapperRef = useRef(null);
    const isScrolled = useRef(false);
    const [element, { height }] = useElementSize();
    const { isInternalScrollingDisabled } = useSelector((state) => ({ isInternalScrollingDisabled: state.steps.currentStep !== currentStep }), shallowEqual);
    useScrollPage(wrapperRef, height, 'down', isInternalScrollingDisabled);
    useEffect(() => {
        if (wrapperRef == null || isScrolled.current || !scrollOnMount) {
            return;
        }
        isScrolled.current = true;
        let scrollTo = 0;
        setTimeout(() => {
            if (wrapperRef.current == null) {
                return;
            }
            const wrapperRects = wrapperRef.current.getBoundingClientRect();
            if (window.innerHeight > wrapperRects.height) {
                scrollTo = document.body.offsetHeight;
            }
            else {
                scrollTo = wrapperRects.top + window.scrollY - stickyHeaderHeight;
            }
            window.scrollTo({ top: scrollTo, behavior: 'smooth' });
        }, 200);
    }, [wrapperRef.current, scrollOnMount]);
    return (_jsx("div", Object.assign({}, rest, { ref: wrapperRef, id: `section-${currentStep}`, className: classnames(styles.wrapper, styles[backgroundColor], className, { [styles.noTopSpacing]: disableTopSpacing }), "data-testid": `section-${currentStep}`, children: _jsxs("div", { className: "container", children: [element, children] }) })));
};
export default StepWrapper;
