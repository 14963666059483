import { useSelector, shallowEqual } from 'react-redux';
import { Steps, UserFields, MaritalTypes, RealEstateAdviceTypes } from '../constants';
export const useShowNextButton = (stepName) => {
    const { currentStep, firstUser, partnerInfo, isAdvisor, timeSlot } = useSelector((state) => ({
        currentStep: state.steps.currentStep,
        firstUser: state.signUp.firstUser,
        partnerInfo: state.partner.partnerInfo,
        isAdvisor: state.partner.isAdvisor,
        timeSlot: state.signUp.timeSlot
    }), shallowEqual);
    if (currentStep !== stepName || firstUser == null) {
        return false;
    }
    switch (stepName) {
        case Steps.ADVISOR:
            return getShowAdvisorNextButton({ firstUser, partnerInfo, isAdvisor });
        case Steps.USER_DETAILS:
            return true;
        case Steps.MARITAL:
            return getShowMaritalNextButton(firstUser);
        case Steps.REAL_ESTATE:
            return getShowRealEstateNextButton(firstUser, isAdvisor);
        case Steps.BOOKING:
            return getShowBookingNextButton(firstUser, timeSlot);
        case Steps.ADDITIONAL_INFO:
            return true;
        default:
            return true;
    }
};
function getShowAdvisorNextButton({ firstUser, isAdvisor, partnerInfo }) {
    var _a;
    const forceAdvisor = (_a = (!isAdvisor && (partnerInfo === null || partnerInfo === void 0 ? void 0 : partnerInfo.showAdvisorOnUserOnboarding))) !== null && _a !== void 0 ? _a : false;
    if ((firstUser === null || firstUser === void 0 ? void 0 : firstUser[UserFields.PARTNER_PRESENT]) != null || forceAdvisor) {
        return true;
    }
    return false;
}
function getShowMaritalNextButton(firstUser) {
    if (firstUser[UserFields.CHILDREN_OUTSIDE_RELATIONSHIP] == null && (firstUser === null || firstUser === void 0 ? void 0 : firstUser[UserFields.MARITAL]) !== MaritalTypes.MARITAL_SINGLE) {
        return false;
    }
    if (firstUser[UserFields.CHILDREN] == null && (firstUser === null || firstUser === void 0 ? void 0 : firstUser[UserFields.MARITAL]) === MaritalTypes.MARITAL_SINGLE) {
        return false;
    }
    return true;
}
function getShowRealEstateNextButton(firstUser, isAdvisor) {
    var _a;
    if ((firstUser === null || firstUser === void 0 ? void 0 : firstUser[UserFields.IS_REAL_ESTATE_PLANNED]) != null && !firstUser[UserFields.IS_REAL_ESTATE_PLANNED]) {
        return true;
    }
    if ((firstUser === null || firstUser === void 0 ? void 0 : firstUser[UserFields.ADVISOR_PHONE]) == null && isAdvisor) {
        return false;
    }
    if ((firstUser === null || firstUser === void 0 ? void 0 : firstUser[UserFields.REAL_ESTATE_ADVICE_TYPE]) === RealEstateAdviceTypes.OTHER) {
        return true;
    }
    if ((firstUser === null || firstUser === void 0 ? void 0 : firstUser[UserFields.REAL_ESTATE_PURCHASE_TYPE]) == null) {
        return false;
    }
    if ((firstUser === null || firstUser === void 0 ? void 0 : firstUser[UserFields.REAL_ESTATE_ADVICE_TYPE]) === RealEstateAdviceTypes.FAMILY_TRANSFER) {
        if ((firstUser === null || firstUser === void 0 ? void 0 : firstUser[UserFields.REAL_ESTATE_PURCHASE_TYPE]) == null) {
            return false;
        }
    }
    if ((firstUser === null || firstUser === void 0 ? void 0 : firstUser[UserFields.REAL_ESTATE_ADVICE_TYPE]) === RealEstateAdviceTypes.BUYER_ADVICE) {
        if ((firstUser === null || firstUser === void 0 ? void 0 : firstUser[UserFields.REAL_ESTATE_PURCHASE_TYPE]) == null
            || (Array.isArray(firstUser === null || firstUser === void 0 ? void 0 : firstUser[UserFields.REAL_ESTATE_PURCHASE_TYPE])
                && ((_a = firstUser[UserFields.REAL_ESTATE_PURCHASE_TYPE]) === null || _a === void 0 ? void 0 : _a.length) === 0)) {
            return false;
        }
    }
    return true;
}
function getShowBookingNextButton(firstUser, timeSlot) {
    if ((firstUser === null || firstUser === void 0 ? void 0 : firstUser[UserFields.ENABLED_DOCUMENTS_AND_TIME_BOOKING]) != null && !firstUser[UserFields.ENABLED_DOCUMENTS_AND_TIME_BOOKING]) {
        return true;
    }
    if (firstUser[UserFields.ENABLED_DOCUMENTS_AND_TIME_BOOKING] && timeSlot != null) {
        return true;
    }
    return false;
}
