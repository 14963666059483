var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faTimes } from '@fortawesome/pro-regular-svg-icons';
import styles from './Alert.module.scss';
const Alert = (_a) => {
    var { type = 'error', onClose, className, children } = _a, props = __rest(_a, ["type", "onClose", "className", "children"]);
    return createPortal((_jsxs("div", Object.assign({}, props, { className: classnames(styles.wrapper, className, styles[type]), children: [_jsx(FontAwesomeIcon, { className: styles.leftIcon, icon: resolveAlertIcon(type) }), _jsx("div", { className: styles.content, children: children }), _jsx("button", { type: "button", onClick: onClose, className: styles.closeButton, "data-cy": "alert-close-button", children: _jsx(FontAwesomeIcon, { icon: faTimes }) })] }))), document.body);
};
function resolveAlertIcon(type) {
    switch (type) {
        case 'warning':
        case 'success':
            return faCheckCircle;
        case 'error':
        default:
            return faExclamationCircle;
    }
}
export default Alert;
