import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from '@hooks';
import { Select, Option } from '@frontend-components/components/Select';
import IconButton from '@frontend-components/components/IconButton';
import styles from './DocumentLine.module.scss';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { digitalProducts } from '@frontend-components/constants';
import { packageSupportedPurchaseTypes } from '@constants';
const DocumentLine = ({ documentInfo, onDelete = null, onChange, isTitleVisible }) => {
    var _a;
    const { t } = useTranslation();
    const selectDocumentTitleElement = isTitleVisible ? _jsx("p", { className: styles.itemTitle, dangerouslySetInnerHTML: { __html: t('steps.documents.selectDocumentTitle') } }) : null;
    const selectPurchaseTypeTitleElement = isTitleVisible ? _jsx("p", { className: styles.itemTitle, dangerouslySetInnerHTML: { __html: t('steps.documents.selectTypeTitle') } }) : null;
    const deleteButtonElement = documentInfo.canDelete ? (_jsx(IconButton, { className: styles.deleteButton, icon: faTimes, title: "delete document", onClick: () => typeof onDelete === 'function' && onDelete(documentInfo.id) })) : null;
    return (_jsxs("div", { className: styles.wrapper, children: [_jsxs("div", { className: styles.item, children: [selectDocumentTitleElement, _jsx(Select, { name: `document-${documentInfo.id}`, label: t('steps.documents.selectDocumentLabel'), value: (_a = documentInfo.value) !== null && _a !== void 0 ? _a : '', onChange: (value) => onChange(value, 'value', documentInfo.id), classNames: { wrapper: styles.select }, selectExtraProps: { 'data-testid': documentInfo.value }, children: digitalProducts.map((product) => _jsx(Option, { value: product, children: t(`global.products.${product}`) }, product)) })] }), _jsxs("div", { className: styles.item, children: [selectPurchaseTypeTitleElement, _jsx(Select, { label: t('steps.documents.selectTypeLabel'), name: `document-type-${documentInfo.id}`, value: documentInfo.purchase_type, onChange: (value) => onChange(value, 'purchase_type', documentInfo.id), classNames: { wrapper: styles.select }, selectExtraProps: { 'data-testid': documentInfo.purchase_type }, children: packageSupportedPurchaseTypes.map((purchaseType) => _jsx(Option, { value: purchaseType, children: t(`global.purchaseTypes.${purchaseType}`) }, purchaseType)) }), deleteButtonElement] })] }));
};
export default DocumentLine;
