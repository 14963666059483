import { combineReducers } from '@reduxjs/toolkit';
import stepsReducer from './slices/stepsSlice';
import partnerReducer from './slices/partnerSlice';
import signUpReducer from './slices/signUpSlice';
const rootReducer = combineReducers({
    steps: stepsReducer,
    partner: partnerReducer,
    signUp: signUpReducer
});
export default rootReducer;
