import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    completedSteps: [],
    currentStep: null,
    isFinalStep: false
};
const routeSlice = createSlice({
    name: 'steps',
    initialState,
    reducers: {
        setCurrentStep(state, action) {
            state.completedSteps = [...state.completedSteps, action.payload];
            state.currentStep = action.payload;
        },
        setCompletedSteps(state, action) {
            state.completedSteps = action.payload;
        },
        setFinalStep(state, action) {
            state.isFinalStep = action.payload;
        },
        resetStore() {
            return initialState;
        }
    }
});
export const { setCurrentStep, setCompletedSteps, setFinalStep, resetStore } = routeSlice.actions;
export default routeSlice.reducer;
