import { createSelector } from '@reduxjs/toolkit';
const partnerRealEstateBranchesSelect = (state) => { var _a; return (_a = state.partner.partnerInfo) === null || _a === void 0 ? void 0 : _a.showRealEstateBranches; };
const branchIdSelect = (state) => { var _a; return (_a = state.signUp.firstUser) === null || _a === void 0 ? void 0 : _a.branchId; };
const isAdvisorSelect = (state) => state.partner.isAdvisor;
export const selectIsRealEstateEnabled = createSelector([partnerRealEstateBranchesSelect, branchIdSelect, isAdvisorSelect], (realEstateBranches, selectedBranch, isAdvisor) => {
    if (!isAdvisor && realEstateBranches != null && realEstateBranches.length > 0)
        return true;
    if (selectedBranch == null || realEstateBranches == null)
        return false;
    return realEstateBranches.includes(selectedBranch);
});
