import axios from 'axios';
import { Cookie } from '../cookie';
import { authHeaderName } from '../../constants';
export class Requester {
    static get(url, config) {
        return axios.get(url, config);
    }
    static post(url, data, config) {
        return axios.post(url, data, config);
    }
    static delete(url, config) {
        return axios.delete(url, config);
    }
    static put(url, data, config) {
        return axios.put(url, data, config);
    }
    static patch(url, data, config) {
        return axios.patch(url, data, config);
    }
    static requestAuthHeaderGet(cookieName) {
        let token = '';
        const cookie = Cookie.get(cookieName);
        if (cookie != null) {
            token = JSON.parse(cookie).token;
        }
        return { [authHeaderName]: token };
    }
}
