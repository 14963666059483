import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getBookings } from '@api/bookings';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { setTimeSlot } from '@store/slices/signUpSlice';
import DayPicker from '@frontend-components/features/TimeBooking/DayPicker';
import QuestionSection from '@components/QuestionSection';
import styles from './TimeBooking.module.scss';
const TimeBooking = () => {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const { t } = useTranslation();
    const { timeSlot, purchaseType, partner, products } = useSelector((state) => {
        var _a;
        return ({
            timeSlot: state.signUp.timeSlot,
            partner: (_a = state.partner.partnerInfo) === null || _a === void 0 ? void 0 : _a.id,
            products: state.signUp.documents != null ? state.signUp.documents.filter((document) => document.value != null).map((document) => document.value).join(',') : null,
            purchaseType: state.signUp.documents != null ? state.signUp.documents.filter((document) => document.value != null)[0].purchase_type : null
        });
    }, shallowEqual);
    return (_jsx(QuestionSection, { text: t('steps.additionalInfo.booking.title'), children: _jsx("div", { "data-testid": "time-picker", className: styles.wrapper, children: _jsx(DayPicker, { selectedTimeSlot: timeSlot !== null && timeSlot !== void 0 ? timeSlot : null, onChangeTimeSlot: handleChangeTimeSlot, fetchTimeSlotsPromise: fetchTimeSlots, onChangeTimeSlotsLoading: setLoading, isTimeSlotsLoading: isLoading, classNames: {
                    wrapper: styles.dayPickerWrapper,
                    wrapperCenter: styles.dayPickerWrapperCenter
                } }) }) }));
    function handleChangeTimeSlot(newTimeSlot) {
        if (newTimeSlot == null)
            return;
        dispatch(setTimeSlot(newTimeSlot));
    }
    function fetchTimeSlots(from, to) {
        if (purchaseType == null || partner == null || products == null || isLoading)
            return null;
        return getBookings({
            purchaseType, partner, products, from, to
        });
    }
};
export default TimeBooking;
