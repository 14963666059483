import { jsx as _jsx } from "react/jsx-runtime";
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { sentryInit } from '@frontend-components/utils/sentry';
import store from './src/store/store';
import App from './src/App';
import '@frontend-components/css/global.scss';
import './i18n';
const { APP_ENV, SENTRY_URL } = process.env;
if (APP_ENV === 'production' && SENTRY_URL != null) {
    sentryInit(SENTRY_URL);
}
const AppToRender = () => (_jsx(StrictMode, { children: _jsx(Provider, { store: store, children: _jsx(App, {}) }) }));
const targetContainer = document.getElementById('reference-spa-wrapper');
if (targetContainer != null) {
    const root = createRoot(targetContainer);
    root.render(_jsx(AppToRender, {}));
}
