import { UserFields, MeetingTypes, UserToUpdate } from '@constants';
import i18n from '../../i18n';
export function generateTicketData(firstUser, secondUser, documents, booking) {
    var _a, _b;
    let content = [i18n.t('process'), '', `url: ${window.location.href}`];
    if ((firstUser === null || firstUser === void 0 ? void 0 : firstUser[UserFields.ADVISOR_ID]) != null) {
        content = [
            ...content,
            `${i18n.t('steps.advisor.advisorLabel')}: ${firstUser[UserFields.ADVISOR_ID]}`,
            `${i18n.t('steps.advisor.branchLabel')}: ${firstUser[UserFields.BRANCH_ID]}`,
            `${i18n.t('steps.advisor.meetingLocationText')}: ${firstUser[UserFields.MEETING_TYPE] === MeetingTypes.PHONE ? i18n.t('steps.advisor.phoneMeeting') : i18n.t('steps.advisor.faceToFaceMeeting')}`,
            `${i18n.t('steps.advisor.sharingScreenText')}: ${firstUser[UserFields.SHARING_SCREEN] ? i18n.t('steps.yes') : i18n.t('steps.no')}`,
            `${i18n.t('steps.advisor.partnerPresentsText')}: ${firstUser[UserFields.PARTNER_PRESENT] ? i18n.t('steps.yes') : i18n.t('steps.no')}`
        ];
    }
    content = [
        ...content,
        '',
        `${i18n.t('steps.userInfo.firstUser')}`,
        '',
        `${i18n.t('steps.userInfo.fullName')}: ${firstUser[UserFields.FIRST_NAME]} ${firstUser[UserFields.LAST_NAME]}`,
        `${i18n.t('steps.userInfo.emailLabel')}: ${firstUser[UserFields.EMAIL]}`,
        `${i18n.t('steps.userInfo.phoneLabel')}: ${firstUser[UserFields.PHONE]}`
    ];
    if (firstUser[UserFields.PARTNER_PRESENT]) {
        content = [
            ...content,
            '',
            `${i18n.t('steps.userInfo.secondUser')}`,
            '',
            `${i18n.t('steps.userInfo.fullName')}: ${secondUser[UserFields.FIRST_NAME]} ${secondUser[UserFields.LAST_NAME]}`,
            `${i18n.t('steps.userInfo.emailLabel')}: ${secondUser[UserFields.EMAIL]}`,
            `${i18n.t('steps.userInfo.phoneLabel')}: ${secondUser[UserFields.PHONE]}`,
            `${i18n.t('steps.userInfo.primaryContactRadio')}: ${firstUser[UserFields.PRIMARY_CONTACT] === UserToUpdate.SECOND ? i18n.t('steps.userInfo.secondUser') : i18n.t('steps.userInfo.firstUser')}`
        ];
    }
    content = [
        ...content,
        '',
        `${i18n.t('steps.marital.marital.title')}: ${i18n.t(`steps.marital.marital.${firstUser[UserFields.MARITAL]}`)}`,
        `${i18n.t('steps.marital.residence.title')}: ${firstUser[UserFields.RESIDENCE] ? i18n.t('steps.yes') : i18n.t('steps.no')}`,
        `${i18n.t('steps.marital.marriage.title')}: ${firstUser[UserFields.MARRIAGE] ? i18n.t('steps.yes') : i18n.t('steps.no')}`,
        `${i18n.t('steps.marital.children.title')}: ${firstUser[UserFields.CHILDREN] ? i18n.t('steps.yes') : i18n.t('steps.no')}`,
        `${i18n.t('steps.marital.childrenOutsideRelationship.title')}: ${firstUser[UserFields.CHILDREN_OUTSIDE_RELATIONSHIP] ? i18n.t('steps.yes') : i18n.t('steps.no')}`
    ];
    if ((firstUser === null || firstUser === void 0 ? void 0 : firstUser[UserFields.ASSETS]) != null) {
        content = [
            ...content,
            '',
            `${i18n.t('steps.marital.assets.title')}: ${JSON.stringify(firstUser[UserFields.ASSETS])}`
        ];
    }
    // Real Estate questions
    if ((firstUser === null || firstUser === void 0 ? void 0 : firstUser[UserFields.IS_REAL_ESTATE_PLANNED]) != null) {
        content = [
            ...content,
            '',
            `${i18n.t('steps.realEstate.planningToBuy.title')}: ${firstUser[UserFields.IS_REAL_ESTATE_PLANNED] ? i18n.t('steps.yes') : i18n.t('steps.no')}`
        ];
    }
    if ((firstUser === null || firstUser === void 0 ? void 0 : firstUser[UserFields.REAL_ESTATE_ADDRESS]) != null) {
        content = [
            ...content,
            '',
            `${i18n.t('steps.realEstate.address.title')}: ${firstUser[UserFields.REAL_ESTATE_ADDRESS]}`
        ];
    }
    if ((firstUser === null || firstUser === void 0 ? void 0 : firstUser[UserFields.REAL_ESTATE_ADVICE_TYPE]) != null) {
        content = [
            ...content,
            '',
            `${i18n.t('steps.realEstate.adviceType.title')}: ${i18n.t(`steps.realEstate.adviceType.options.${firstUser[UserFields.REAL_ESTATE_ADVICE_TYPE]}`)}`
        ];
    }
    if ((firstUser === null || firstUser === void 0 ? void 0 : firstUser[UserFields.REAL_ESTATE_LOAN_PROOF]) != null) {
        content = [
            ...content,
            '',
            `${i18n.t('steps.realEstate.loan.title')}: ${firstUser[UserFields.REAL_ESTATE_LOAN_PROOF] ? i18n.t('steps.yes') : i18n.t('steps.no')}`
        ];
    }
    if ((firstUser === null || firstUser === void 0 ? void 0 : firstUser[UserFields.REAL_ESTATE_PURCHASE_AGREEMENT_SIGNED]) != null) {
        content = [
            ...content,
            '',
            `${i18n.t('steps.realEstate.purchaseAgreementSigned.title')}: ${firstUser[UserFields.REAL_ESTATE_PURCHASE_AGREEMENT_SIGNED] ? i18n.t('steps.yes') : i18n.t('steps.no')}`
        ];
    }
    if ((firstUser === null || firstUser === void 0 ? void 0 : firstUser[UserFields.REAL_ESTATE_PURCHASE_TYPE]) != null) {
        content = [
            ...content,
            '',
            `${i18n.t('steps.realEstate.purchaseTypes.title')}: ${(_a = firstUser[UserFields.REAL_ESTATE_PURCHASE_TYPE]) === null || _a === void 0 ? void 0 : _a.map((type) => i18n.t(`steps.realEstate.types.${type}`)).join(', ')}`
        ];
    }
    if ((firstUser === null || firstUser === void 0 ? void 0 : firstUser[UserFields.REAL_ESTATE_SEARCH_STATUS]) != null) {
        content = [
            ...content,
            '',
            `${i18n.t('steps.realEstate.searchStatus.title')}: ${i18n.t(`steps.realEstate.searchStatus.options.${firstUser[UserFields.REAL_ESTATE_SEARCH_STATUS]}`)}`
        ];
    }
    content = [
        ...content,
        '',
        `${i18n.t('steps.consents.title')}: ${JSON.stringify(firstUser[UserFields.CONSENTS])}`
    ];
    if (firstUser[UserFields.DOCUMENTS] != null) {
        content = [
            ...content,
            '',
            `${i18n.t('steps.additionalInfo.documents.titleAlone')}: ${(_b = firstUser === null || firstUser === void 0 ? void 0 : firstUser[UserFields.DOCUMENTS]) !== null && _b !== void 0 ? _b : [].map((document) => i18n.t(`products.${document}`)).join(', ')}`
        ];
    }
    if (firstUser[UserFields.COMMENT]) {
        content = [
            ...content,
            '',
            `${i18n.t('steps.additionalInfo.comment.title')}: ${firstUser[UserFields.COMMENT]}`
        ];
    }
    if (documents != null) {
        content = [
            ...content,
            '',
            `${i18n.t('steps.documents.selectDocumentTitle')}: ${documents.map((document) => document.value).join(', ')}`,
            `${i18n.t('steps.documents.selectTypeTitle')}: ${documents.map((document) => document.purchase_type).join(', ')}`
        ];
    }
    if (booking != null) {
        content = [
            ...content,
            '',
            `${i18n.t('steps.additionalInfo.booking.title')}: ${JSON.stringify(booking)}`
        ];
    }
    return content;
}
