import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from '@hooks';
import styles from './FinalStep.module.scss';
import michael from '@public/images/michael.png';
import flyasset from '@public/images/flyasset.png';
const FinalStep = () => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: `${styles.wrapper} container`, children: [_jsx("h1", { className: styles.title, children: t('steps.final.title') }), _jsxs("div", { className: styles.content, children: [_jsxs("div", { className: styles.imageSection, children: [_jsxs("div", { children: [_jsx("p", { className: styles.text, dangerouslySetInnerHTML: { __html: t('steps.final.text1') } }), _jsx("p", { className: styles.boldText, dangerouslySetInnerHTML: { __html: t('steps.final.textBold') } })] }), _jsx("img", { className: styles.image, src: flyasset, alt: "Envolope" })] }), _jsx("p", { className: styles.text, dangerouslySetInnerHTML: { __html: t('steps.final.text2') } }), _jsx("p", { className: styles.text, dangerouslySetInnerHTML: { __html: t('steps.final.text3') } }), _jsx("p", { className: styles.text, dangerouslySetInnerHTML: { __html: t('steps.final.text4') } }), _jsxs("div", { className: styles.quote, children: [_jsx("img", { className: styles.image, src: michael, alt: "Michael" }), _jsxs("div", { children: [_jsx("h3", { className: styles.title, dangerouslySetInnerHTML: { __html: t('steps.final.quoteTitle') } }), _jsx("p", { className: styles.text, dangerouslySetInnerHTML: { __html: t('steps.final.quoteText') } })] })] })] })] }));
};
export default FinalStep;
