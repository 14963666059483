var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/jsx-no-useless-fragment */
import { useState, useEffect, useCallback } from 'react';
import ReactInputMask from 'react-input-mask';
import { extractTextFromReact } from '../../utils/extractTextFromReact';
import InputWrapper from '../../helperComponents/InputWrapper';
const InputMask = (_a) => {
    var _b;
    var { value, label, placeholder, isControlledOnly, name, disabled, onChange, leftIcon, rightIcon, isError, errorMessage, inputSize, kind, mask } = _a, props = __rest(_a, ["value", "label", "placeholder", "isControlledOnly", "name", "disabled", "onChange", "leftIcon", "rightIcon", "isError", "errorMessage", "inputSize", "kind", "mask"]);
    const [inputValue, setValue] = useState(value !== null && value !== void 0 ? value : '');
    const [isFocused, setFocused] = useState(false);
    const areaLabelText = (_b = props['aria-label']) !== null && _b !== void 0 ? _b : extractTextFromReact(label !== null && label !== void 0 ? label : placeholder);
    useEffect(() => {
        if (value != null) {
            setValue(value);
        }
    }, [value]);
    const handleChangeInput = useCallback((event) => {
        if (disabled) {
            return;
        }
        if (!isControlledOnly) {
            setValue(event.target.value);
        }
        if (typeof onChange === 'function') {
            onChange(event);
        }
    }, [disabled, isControlledOnly, onChange]);
    const handleFocus = useCallback(() => {
        if (disabled) {
            return;
        }
        setFocused(true);
    }, [disabled]);
    const handleBlur = useCallback(() => {
        setFocused(false);
    }, []);
    return (_jsx(InputWrapper, Object.assign({}, props, { inputSize: inputSize, kind: kind, name: name, value: inputValue, fixedLabel: true, leftIcon: leftIcon, rightIcon: rightIcon, isError: isError, errorMessage: errorMessage, label: label, placeholder: placeholder, disabled: disabled, isFocused: isFocused, children: _jsx(ReactInputMask, { value: value, mask: mask, alwaysShowMask: true, onChange: (event) => handleChangeInput(event), onFocus: handleFocus, onBlur: handleBlur, children: (inputProps) => (_jsx("input", Object.assign({}, inputProps, props, { name: name, "aria-label": areaLabelText, disabled: disabled }))) }) })));
};
export default InputMask;
