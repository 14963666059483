import { jsx as _jsx } from "react/jsx-runtime";
import { useRef, useState, useMemo, useEffect } from 'react';
const style = {
    display: 'block',
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    pointerEvents: 'none',
    zIndex: -1,
    border: 0
};
export function useElementSize() {
    const ref = useRef(null);
    const [state, setState] = useState({ width: 0, height: 0 });
    useEffect(() => {
        if (ref.current == null) {
            return;
        }
        const handleResize = () => {
            const rects = ref.current.getBoundingClientRect();
            setState({ width: rects.width, height: rects.height });
        };
        handleResize();
        ref.current.contentDocument.defaultView.addEventListener('resize', handleResize);
    }, []);
    const element = useMemo(() => (_jsx("iframe", { title: "resize-iframe", style: style, ref: ref, src: "about:blank", "aria-hidden": true, "aria-label": "resize-listener", tabIndex: -1 })), []);
    return [element, state];
}
