var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useToggleElementVisibility } from '../../hooks/useToggleElementVisibility';
import Message from '../Message';
import styles from './RadioButton.module.scss';
const RadioButton = (_a) => {
    var { name, checked = false, label = null, disabled = false, isControlledOnly = false, classNames, isError = false, errorMessage = null, handleOnChange } = _a, props = __rest(_a, ["name", "checked", "label", "disabled", "isControlledOnly", "classNames", "isError", "errorMessage", "handleOnChange"]);
    const [isChecked, setIsChecked] = useState(checked);
    const [isFocused, setFocused] = useState(false);
    const [isRippleVisible, setRippleVisible] = useToggleElementVisibility();
    useEffect(() => {
        if (isChecked !== checked) {
            setIsChecked(checked);
        }
    }, [checked]);
    return (_jsxs("div", Object.assign({}, ((classNames === null || classNames === void 0 ? void 0 : classNames.wrapper) != null ? { className: classNames.wrapper } : {}), { children: [_jsxs("label", { htmlFor: name, className: styles.label, onClick: handleClick, children: [_jsxs("div", { className: classnames(styles.customRadioWrapper, {
                            [styles.checked]: isChecked,
                            [styles.focused]: isFocused,
                            [styles.disabled]: disabled,
                            [styles.error]: isError
                        }), children: [_jsx("div", { className: styles.radio }), _jsx("div", { className: styles.hoverBackground }), isRippleVisible ? _jsx("div", { className: styles.ripple }) : null] }), label != null ? (_jsx("p", { className: classnames(styles.labelText, classNames === null || classNames === void 0 ? void 0 : classNames.labelText), children: label })) : null, _jsx("input", Object.assign({}, props, { name: name, onFocus: () => setFocused(true), onBlur: () => setFocused(false), type: "radio", checked: isChecked, value: isChecked ? 1 : 0, onChange: handleClick, className: styles.radioInput, disabled: disabled, onKeyDown: handleKeyDown }))] }), errorMessage != null && errorMessage ? (_jsx(Message, { type: "error", className: styles.errorMessage, children: errorMessage })) : null] })));
    function handleClick() {
        var _a;
        if (isChecked || disabled) {
            return;
        }
        if (!isControlledOnly) {
            setIsChecked(true);
        }
        if (typeof handleOnChange === 'function') {
            handleOnChange((_a = props.id) !== null && _a !== void 0 ? _a : name);
        }
        setRippleVisible();
    }
    function handleKeyDown(event) {
        event.stopPropagation();
        if (event.key === 'Enter') {
            event.preventDefault();
            handleClick();
        }
    }
};
export default RadioButton;
