import { useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { setCurrentStep } from '@store/slices/stepsSlice';
import { selectIsBookingEnabled } from '@store/selectors/bookingSelector';
import { selectIsRealEstateEnabled } from '@store/selectors/realEstateSelector';
import { Steps } from '../constants';
export const useGoToNextStep = () => {
    const dispatch = useDispatch();
    const { currentStep, isBookingEnabled, isRealEstateEnabled } = useSelector((state) => ({
        currentStep: state.steps.currentStep,
        isBookingEnabled: selectIsBookingEnabled(state),
        isRealEstateEnabled: selectIsRealEstateEnabled(state)
    }), shallowEqual);
    const handler = useCallback(() => {
        let nextCurrentStep = Steps.ADVISOR;
        switch (currentStep) {
            case Steps.ADVISOR:
                nextCurrentStep = Steps.USER_DETAILS;
                break;
            case Steps.USER_DETAILS:
                nextCurrentStep = Steps.MARITAL;
                break;
            case Steps.MARITAL:
                if (isRealEstateEnabled) {
                    nextCurrentStep = Steps.REAL_ESTATE;
                }
                else if (isBookingEnabled) {
                    nextCurrentStep = Steps.BOOKING;
                }
                else {
                    nextCurrentStep = Steps.ADDITIONAL_INFO;
                }
                break;
            case Steps.REAL_ESTATE:
                if (isBookingEnabled) {
                    nextCurrentStep = Steps.BOOKING;
                }
                else {
                    nextCurrentStep = Steps.ADDITIONAL_INFO;
                }
                break;
            case Steps.BOOKING:
                nextCurrentStep = Steps.ADDITIONAL_INFO;
                break;
            case Steps.ADDITIONAL_INFO:
                nextCurrentStep = Steps.CONSENTS;
                break;
            default:
                break;
        }
        dispatch(setCurrentStep(nextCurrentStep));
    }, [currentStep, isRealEstateEnabled, isBookingEnabled]);
    return [handler];
};
