var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useToggleElementVisibility } from '../../hooks/useToggleElementVisibility';
import Message from '../Message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import styles from './Checkbox.module.scss';
const Checkbox = (_a) => {
    var { name, checked = false, label = null, disabled = false, isControlledOnly = false, classNames, isError = false, errorMessage = null, handleOnChange, color = 'default' } = _a, props = __rest(_a, ["name", "checked", "label", "disabled", "isControlledOnly", "classNames", "isError", "errorMessage", "handleOnChange", "color"]);
    const [isChecked, setIsChecked] = useState(checked);
    const [isFocused, setFocused] = useState(false);
    const [isRippleVisible, setRippleVisible] = useToggleElementVisibility();
    const { 'data-testid': testId } = props, rest = __rest(props, ['data-testid']);
    useEffect(() => {
        if (isChecked !== checked) {
            setIsChecked(checked);
        }
    }, [checked]);
    return (_jsxs("div", Object.assign({}, ((classNames === null || classNames === void 0 ? void 0 : classNames.wrapper) != null ? { className: classNames.wrapper } : {}), { children: [_jsxs("label", Object.assign({}, (testId != null ? { 'data-testid': testId } : {}), { htmlFor: name, className: classnames(styles.label, styles[color]), onClick: handleClick, children: [_jsxs("div", { className: classnames(styles.customCheckbox, {
                            [styles.checked]: isChecked,
                            [styles.focused]: isFocused,
                            [styles.disabled]: disabled,
                            [styles.error]: isError
                        }), children: [_jsx("div", { className: styles.checkbox, children: isChecked ? _jsx(FontAwesomeIcon, { icon: faCheck, className: styles.checkMark }) : null }), isRippleVisible ? _jsx("div", { className: styles.ripple }) : null, _jsx("div", { className: styles.hoverBackground })] }), label != null ? (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    _jsx(_Fragment, { children: typeof label === 'string' ? (_jsx("p", { className: classnames(styles.labelText, classNames === null || classNames === void 0 ? void 0 : classNames.labelText, { [styles.empty]: !label }), dangerouslySetInnerHTML: { __html: label } })) : (_jsx("p", { className: classnames(styles.labelText, classNames === null || classNames === void 0 ? void 0 : classNames.labelText, { [styles.empty]: !label }), children: label })) })) : null, _jsx("input", Object.assign({}, rest, { name: name, onFocus: () => setFocused(true), onBlur: () => setFocused(false), type: "checkbox", disabled: disabled, className: styles.checkboxInput, checked: isChecked, value: isChecked ? 1 : 0, onChange: handleClick, 
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        onKeyDown: handleKeyDown }))] })), errorMessage != null && errorMessage ? (_jsx(Message, { type: "error", className: styles.errorMessage, children: errorMessage })) : null] })));
    function handleClick() {
        if (disabled) {
            return;
        }
        if (typeof handleOnChange === 'function') {
            handleOnChange(!isChecked);
        }
        if (!isControlledOnly) {
            setIsChecked(!isChecked);
        }
        if (isControlledOnly && typeof handleOnChange !== 'function') {
            return;
        }
        setRippleVisible();
    }
    function handleKeyDown(event) {
        event.stopPropagation();
        if (event.key === 'Enter') {
            event.preventDefault();
            handleClick();
        }
    }
};
export default Checkbox;
